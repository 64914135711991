// $filePath: '/dirname/assets/';

$base-color: #ff0000;
$text-color: #333;
$color1: #292929;
$color2: #fff;
$color3: #0059df;
$color4: #00359d;
$color5: #0066ff;
$color6: #00dc9a;
$color7: #a6a6a6;
$color8: #e7e7e7;
$color9: #f2f4f5;
$color10: linear-gradient(
  140.28deg,
  #fcf3e7 17.02%,
  #e0ecf7 58.51%,
  #d2e4ff 82.86%
);
$color11: linear-gradient(180deg, #fcf3e7 0%, #0066ff 69.27%);
$color12: #0059de;
$color13: linear-gradient(
  145deg,
  #0066ff 0%,
  #0066ff 40%,
  #f2e5b7 80%,
  lighten(#f2e5b7, 37px) 120%
);
$color14: #282828;
$color15: #bfbfbf;
$color16: #d35932;
$color17: #f0f4f7;
$color18: #ffd700;
$color19: #e6eefb;
$color20: #b7b7b7;
$color21: #ececec;
$color22: #e3e3e3;
$color23: #000000;
$color24: linear-gradient(
  140.28deg,
  #fcf3e7 17.02%,
  #e0ecf7 58.51%,
  #d2e4ff 82.86%
);
$color25: #3284ff;
$color26: #e2e2e2;
$color27: radial-gradient(
  64.99% 387.07% at 76.32% 62.65%,
  rgba(255, 255, 255, 0) 0%,
  #fcf3e7 69.15%,
  #d2e4ff 100%
);
$color28: #f2e5b7;
$color29: #fcf3e7;
$color30: #f0f6ff;
$color31: #122e64;
