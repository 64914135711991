@use '../settings/mixin' as r;
@use '../settings/variable' as v;
@use '../settings/_path' as p;

// @use '../../../node_modules/sanitize.css/sanitize.css';
// @use '../../../node_modules/sanitize.css/forms.css';

* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

li {
  list-style-type: none;
}

th {
  font-weight: inherit;
}

address {
  font-style: inherit;
}

fieldset {
  min-width: 0;
  padding: 0;
  border: 0;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

button,
input,
select,
textarea {
  padding: 0;
  border: 0;
}

button:not(:disabled):not([aria-disabled='true']) {
  cursor: pointer;
}

html {
  font-size: 62.5%;
}

body {
  font-weight: 400;
  font-size: 16px;
  position: relative;
  overflow-y: scroll;

  &:not(.page-top) {
    padding-top: 145px;
    @include r.max-w(1380px) {
      padding-top: calc((115 / 1280) * 100vw);
    }
    @include r.pad {
      padding-top: 104px;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100vh;
      min-height: 1039px;
      background: url(p.$path + 'images/common/bg.png') no-repeat top right;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
      @include r.sp {
        min-height: 1399px;
        background: url(p.$path + 'images/common/bg-sp.png') no-repeat top right;
      }
    }
  }
}

// Safari用のハックは、Chromeに適用されないようにする
@supports (-webkit-touch-callout: none) {
  body {
    // Safari用のハック
    height: -webkit-fill-available;
  }
}

[v-cloak] {
  visibility: hidden;
}

.l-wrapper {
  position: relative;
}

.l-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}

.l-container2 {
  max-width: 1380px;
  margin: 0 auto;
  padding: 0 20px;
}

.l-container3 {
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 20px;
}

.pc {
  @include r.mq(mdless) {
    display: none !important;
  }
}

.sp {
  @include r.mq(mdover) {
    display: none !important;
  }
}
.u-ipad {
  @include r.min-w(968px) {
    display: none !important;
  }
}
