@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
other
------------------------------------------------------------*/
.c-news {
  background: #ffffff;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 42px 20px 53px;

  @include r.sp {
    padding: 28px 18px 22px;
  }

  &__title {
    text-align: center;
    margin-bottom: 13px;

    @include r.sp {
      margin-bottom: -3px;
    }
  }

  &__ttl {
    display: block;
    font-family: 'Mulish';
    font-weight: 700;
    font-size: 4.5rem;
    line-height: 56px;
    overflow: hidden;
    position: relative;
    letter-spacing: -0.02em;
    color: #0066ff;
    // background: linear-gradient(
    //   135deg,
    //   #0066ff 0%,
    //   #0066ff 40%,
    //   #f2e5b7 100%,
    //   white 120%
    // );
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;

    @include r.sp {
      font-size: 3.6rem;
      line-height: 45px;
    }
  }

  &__sub {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 32px;
    color: #292929;

    @include r.sp {
      line-height: 20px;
    }
  }

  &__list {
    max-width: 956px;
    margin: auto;
  }

  &__item {
    display: flex;
    //flex-wrap: wrap;
    align-items: center;
    padding: 16px 0 17px;
    border-bottom: 1px solid #e2e2e2;

    @include r.sp {
      padding: 20px 0;
      align-items: baseline;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__date {
    width: 64px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 15px;
    color: #292929;
    flex-shrink: 0;
  }

  &__link {
    margin-left: 27px;
    text-decoration: none;
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: -0.03rem;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @include r.sp {
      line-height: 21px;
      font-size: 1.4rem;
      margin-left: 18px;
      text-overflow: inherit;
      white-space: normal;
      overflow: visible;
    }

    &:hover,
    &.is-active {
      color: #0066ff;
    }
  }
}

/*
------------------------------------------------------------*/
.c-banner {
  display: block;
  max-width: 847px;
  position: relative;
  overflow: hidden;
  height: 200px;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
  margin: auto;
  width: calc(100% - 40px);
  $root: &;
  @include r.sp {
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba($color: #404040, $alpha: 0.4);
    transition: 0.2s linear;
  }

  &:before {
    z-index: 4;
    content: '';
    background: url(p.$path + 'images/common/icon/icon_arrow_right_white.svg')
      no-repeat center/10px auto;
    transition: 0.1s linear;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    width: 85px;
    height: 85px;
    border-radius: 100%;
    border: 1px solid #fff;

    @include r.sp {
      width: 65px;
      height: 65px;
      right: 20px;
    }
  }
  @include r.hover {
    transform: scale(0.98);
    .c-banner {
      &__img {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  &__link {
    font-size: 2.4rem;
    line-height: 48px;
    letter-spacing: 0.1em;
    color: #ffffff;
    position: absolute;
    left: 79px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    @include r.sp {
      font-size: 1.8rem;
      line-height: 36px;
      left: 20px;
      padding-right: 88px;
      width: calc(100% - 20px);
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      position: relative;
      z-index: 0;
      transition: all 0.5s ease-in-out;
    }
  }

  &__txt1 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 24px;
    color: v.$color2;
    letter-spacing: 0.1em;
    display: block;
    position: absolute;
    left: 79px;
    top: 36%;
    transform: translateY(-50%);
    z-index: 3;

    @include r.sp {
      font-size: 1.2rem;
      left: 30px;
      top: calc(50% - 28px);
      transform: translateY(0);
    }

    @include r.max-w(374px) {
      left: 20px;
    }
  }

  &--style1 {
    #{$root} {
      &__link {
        top: 56.3%;
        font-size: 2.8rem;
        line-height: 32px;

        @include r.sp {
          font-size: 1.8rem;
          top: 55.3%;
          line-height: 24px;
          left: 30px;
          padding-right: 80px;
          width: auto;
          transform: translateY(0);
          top: calc(50% - 5px);
        }

        @include r.max-w(374px) {
          left: 20px;
          padding-right: 110px;
        }

        &::before {
          content: '';
          background: v.$color2;
          height: 1px;
          width: 100%;
          display: block;
          margin-bottom: 10px;

          @include r.sp {
            margin-bottom: 4px;
          }
        }
      }
    }
    &::before {
      background: url(p.$path + 'images/common/icon/icon-arrow-right.svg')
        no-repeat center/18px auto;
    }
  }
  &--productNews{
    height: 150px;
  }
}

/*
------------------------------------------------------------*/
.c-modal {
  width: 100%;
  height: 100%;
  background: rgba($color: v.$color23, $alpha: 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.5s;
  $root: &;

  &.is-show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    #{$root}__box {
      transform: translateY(0);
    }
  }

  //------------------------------------------------------
  &__close {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 20px;
    right: 24px;
    cursor: pointer;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    @include r.sp {
      width: 40px;
      height: 40px;
      top: 10px;
      right: 10px;
    }
  }

  //------------------------------------------------------
  &__box {
    background: v.$color2;
    max-width: 1038px;
    width: calc(100% - 40px);
    margin: 0 auto;
    max-height: calc(100vh - 100px);
    overflow: auto;
    padding: 61px 60px 30px 52px;
    position: relative;
    border-radius: 10px;
    transform: translateY(50px);
    transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    @include r.sp {
      max-width: 100%;
      max-height: calc(100vh - 60px);
      max-height: calc(100% - 60px);
      padding: 31px 20px;
    }
  }
}

/*
------------------------------------------------------------*/
.c-tabs {
  max-width: 1240px;
  margin: 0 auto;
  width: calc(100% - 40px);
  @include r.sp {
    width: 100%;
  }
  &__list1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -0.5px;
  }

  &__item1 {
    padding: 0 0.5px;
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link1 {
    background: v.$color9;
    color: v.$color1;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 23px;
    width: 100%;
    text-align: center;
    cursor: pointer;

    font-weight: 600;
    font-size: 2rem;
    line-height: 24px;
    letter-spacing: 0.05em;

    &.inactive {
      background: v.$color7;
      color: v.$color2;
      @include r.hover {
        color: #0066ff;
      }
    }
  }

  &__inner {
    background: v.$color9;
    padding: 50px 20px 12px;

    @include r.sp {
      padding: 50px 20px 23px;
    }
  }

  &__list2 {
    width: 70.5%;
    margin: 0 auto;

    @include r.sp {
      width: 100%;
    }
  }

  &__item2 {
    @include r.sp {
      margin-bottom: 40px;
    }
    &:last-child {
      @include r.sp {
        margin-bottom: 0;
      }
    }
  }
  &__img1 {
    border-radius: 10px;
    overflow: hidden;
  }
  &__note1 {
    display: block;
    text-align: right;
    margin-top: 4px;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 24px;
    margin-right: -7px;
    @include r.sp {
      font-size: 1.4rem;
      margin-top: 35px;
      margin-right: 0;
    }
  }

  &__title1 {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 24px;
    text-align: center;
    color: v.$color1;
    display: block;
  }

  &__img1 {
    @include r.sp {
      margin-top: 16px;
    }
  }
}

/*
------------------------------------------------------------*/
.c-accordion1 {
  &__item {
    border-bottom: 1px solid #e3e3e3;
  }
  &__ttl {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 48px;
    letter-spacing: 0.08em;
    cursor: pointer;
    padding: 14px 55px 17px 32px;
    position: relative;
    transition: 0.3s background ease-in-out;
    @include r.sp {
      padding: 10px 17px;
      font-size: 2rem;
      line-height: 40px;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 15px;
      height: 2px;
      background: #00dc9a;
      right: 31px;
      top: calc(50% - 2px);
      transition: 0.3s all ease-in-out;
      @include r.sp {
        right: 20px;
      }
    }
    &:after {
      transform: rotate(90deg);
    }
    @include r.hover {
      &:not(.is-open) {
        background: #f2f4f5;
      }
    }
    &.is-open {
      &:after {
        transform: rotate(0deg);
      }
    }
  }
  &__body {
    display: none;
  }
  .c-list3 {
    &__item {
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-circle1 {
  z-index: -1;
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
  min-height: 100vh;
  pointer-events: none;
  display: block;

  &__inner {
    display: block;
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100px;
    height: 100px;
    background: linear-gradient(180deg, v.$color29 0%, v.$color30 100%);
    border-radius: 100px;
    transition: all 1s ease-out;

    &.is-hide {
      display: none;
    }
  }
}
