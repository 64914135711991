@charset 'UTF-8';
@use './settings/variable' as v;
@use './settings/mixin' as r;
@use './settings/_path' as p;

// @tailwind utilities;
#tinymce {
  //@include r.YuGothic;
  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 200%;
    position: relative;
    padding-left: 14px;

    @include r.sp {
      font-size: 18px;
      line-height: 160%;
      padding-left: 10px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 2px;
      height: calc(100% - 22px);
      border-left: 2px solid #0066ff;

      @include r.sp {
        top: 4px;
        left: 0px;
        transform: translateY(0);
        height: calc(100% - 8px);
      }
    }

    & + h2 {
      margin-top: 13px;

      @include r.sp {
        margin-top: 30px;
      }
    }
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
    color: v.$color5;
    line-height: 200%;
    margin-top: 22px;
    margin-bottom: 8px;

    @include r.sp {
      line-height: 26px;
      margin-bottom: 19px;
      margin-top: 8px;
    }
  }

  p {
    font-size: 14px;
    line-height: 200%;
    font-weight: 400;
    color: v.$color1;

    @include r.sp {
      line-height: 27px;
    }
  }
}
