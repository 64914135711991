@use 'sass:map';

$breakpoints: (
  'smless': (
    max-width: 640px,
  ),
  'smover': (
    min-width: 641px,
  ),
  'mdless': (
    max-width: 767px,
  ),
  'mdover': (
    min-width: 768px,
  ),
  'lgless': (
    max-width: 1024px,
  ),
  'lgover': (
    min-width: 1025px,
  ),
  'xlless': (
    max-width: 1280px,
  ),
  'xlover': (
    min-width: 1281px,
  ),
  'xxlless': (
    max-width: 1440px,
  ),
  'xxlover': (
    min-width: 1441px,
  ),
  'maxless': (
    max-width: 1920px,
  ),
  'maxover': (
    min-width: 1921px,
  ),
) !default;

@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @error "指定されたブレイクポイントはありません -> #{map-keys($breakpoints)}";
  }
}

@mixin hover {
  @include mq(mdover) {
    &:hover {
      @content;
    }
  }
}

@mixin pc {
  @include mq(mdover) {
    @content;
  }
}

@mixin pad {
  @include max-w(1024px) {
    @content;
  }
}

@mixin sp {
  @include mq(mdless) {
    @content;
  }
}

@mixin min-w($breakpoint) {
  @media screen and (min-width: #{$breakpoint}) {
    @content;
  }
}

@mixin max-w($breakpoint) {
  @media screen and (max-width: #{$breakpoint}) {
    @content;
  }
}

@mixin calc($w1, $prop, $value, $w2) {
  @include max-w($w1 + px) {
    #{$prop}: calc(($value / $w2) * 100vw);
  }
}

@mixin clear {
  *zoom: 1;

  &:after {
    display: block;
    content: '';
    clear: both;
  }
}

@mixin YuGothic {
  font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic',
    'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
}

@mixin Mulish {
  font-family: 'Mulish', sans-serif;
}

@mixin Roboto {
  font-family: 'Roboto', sans-serif;
}
