@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

// .l-footer{}

.c-scrolltop {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 99;
  transition: all 0.5s ease-in-out;

  @include r.pc {
    opacity: 0;
    visibility: hidden;
    &.is-show {
      opacity: 1;
      visibility: visible;
    }
  }

  @include r.sp {
    bottom: 0;
    right: 0;
    width: 100%;
    transform: translateY(0);
    visibility: visible;

    &.is-scrolling {
      transform: translateY(100%);
    }
  }
  a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);

    @include r.sp {
      width: 100%;
      height: auto;
      border-radius: 0;
      box-shadow: none;
      background: v.$color5;
      padding: 18px 0;
    }

    @include r.hover {
      background: v.$color5;

      span {
        color: #fff;
      }
    }

    span {
      display: block;
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: bold;
      color: v.$color1;
      line-height: 120%;
      @include r.Mulish;
      text-align: center;
      transition: all 0.3s ease-out;

      @include r.sp {
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        line-height: 1;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 9px;
        background: url('../images/common/icon/icon_arrow_up_green.svg')
          no-repeat center;
        background-size: 12px 9px;
        margin: 0 auto 5px;

        @include r.sp {
          order: 2;
          flex-shrink: 0;
          width: 12px;
          margin: 0 0 0 5px;
          position: relative;
          top: -2px;
        }
      }
    }
  }
  &__txtform {
    display: none;
    @include r.sp {
      display: block;
      background: #fff;
      box-shadow: 0 4px 50px rgba(0, 0, 0, 0.15);
      font-size: 1.4rem;
      font-weight: 300;
      color: #292929;
      text-align: center;
    }
  }
}

.c-contact {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 81px 133px;
  background: #fff;

  @include r.max-w(1240px) {
    padding: 40px;
  }

  @include r.sp {
    display: block;
    padding: 32px 22px 56px;
  }

  * {
    position: relative;
    z-index: 2;
  }
  &::after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../images/common/ft_contact.jpg') no-repeat center;
    background-size: cover;
    opacity: 0.1;
    z-index: 1;

    @include r.sp {
      opacity: 0.07;
      background: url('../images/common/ft_contact_sp.jpg') no-repeat
        center/cover;
    }
  }

  &__left {
    width: 50%;
    position: relative;
    top: 7px;

    @include r.max-w(1240px) {
      top: 0;
    }

    @include r.sp {
      width: 100%;
    }
  }

  &__right {
    width: 50%;
    margin: 0 16px 0 40px;

    @include r.max-w(1240px) {
      margin: 0 0 0 40px;
    }

    @include r.sp {
      width: 100%;
      margin: 0;
      padding: 0 3px;
    }
  }

  &__title {
    position: relative;
    &--eng {
      position: relative;
      @include r.Mulish;
      font-size: 7.5rem;
      color: v.$color5;
      overflow: hidden;
      font-weight: bold;
      background: v.$color13;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      letter-spacing: -0.015em;

      @include r.max-w(1240px) {
        font-size: 7rem;
      }

      @include r.sp {
        font-size: 4.6rem;
      }
    }
    &--jap {
      display: block;
      font-size: 2.4rem;
      color: v.$color14;
      @include r.YuGothic;
      font-weight: bold;
      position: relative;
      padding-left: 22px;
      margin-top: 8px;

      @include r.max-w(1240px) {
        font-size: 2rem;
      }

      @include r.sp {
        font-size: 1.8rem;
        padding-left: 15px;
        margin-top: 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 16px;
        left: 4px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: v.$color5;

        @include r.max-w(1240px) {
          top: 50%;
          transform: translateY(-50%);
        }

        @include r.sp {
          width: 4px;
          height: 4px;
        }
      }
    }
  }

  &__btn {
    margin: 33px 10px;

    @include r.sp {
      margin: 18px 4px 47px;
    }
    a {
      background: v.$color5;
      color: #fff;
      display: flex;
      align-items: center;
      border-radius: 5px;
      max-width: 330px;
      @include r.YuGothic;
      font-size: 1.8rem;
      font-weight: bold;
      padding: 10px 22px;

      @include r.hover {
        transform: scale(0.9);
      }

      @include r.max-w(1240px) {
        font-size: 1.6rem;
        padding: 10px;
        justify-content: space-between;
      }

      @include r.sp {
        padding: 10px 13px;
      }

      span {
        margin: 0 10px 0 9px;

        @include r.sp {
          margin: 0 7px 0 9px;
        }
      }

      &::before {
        content: '';
        width: 40px;
        height: 40px;
        background: url('../images/common/icon/icon_mail02.svg') no-repeat
          center;
        background-size: 40px 40px;
        flex-shrink: 0;

        @include r.max-w(1240px) {
          width: 35px;
          height: 35px;
          background-size: 35px 35px;
        }

        @include r.sp {
          width: 40px;
          height: 40px;
          background-size: 40px 40px;
        }
      }

      &::after {
        content: '';
        width: 9px;
        height: 12px;
        background: url(p.$path + 'images/common/icon/icon_arrow_right_white.svg')
          no-repeat center;
        background-size: 9px 12px;
      }
    }
  }

  &__info {
    & + .c-contact__info {
      margin-top: 32px;
      padding-top: 35px;
      border-top: 1px solid #bfbfbf;

      @include r.sp {
        margin-top: 24px;
        padding-top: 26px;
      }
    }

    &:nth-child(2) {
      .c-contact__tit {
        @include r.sp {
          margin-bottom: 12px;
        }
      }
    }
  }

  &__tit {
    @include r.YuGothic;
    font-weight: bold;
    margin-bottom: 17px;

    @include r.sp {
      margin-bottom: 8px;
    }

    &1 {
      font-size: 2.6rem;
      color: v.$color14;

      @include r.max-w(1240px) {
        font-size: 2.2rem;
      }

      @include r.sp {
        font-size: 2.4rem;
        line-height: 130%;
      }
    }

    &2 {
      display: block;
      font-size: 1.8rem;
      color: v.$color5;
      margin-top: -3px;

      @include r.max-w(1240px) {
        font-size: 1.4rem;
      }

      @include r.sp {
        font-size: 1.6rem;
        margin-top: 1px;
      }
    }
  }

  &__phone {
    @include r.Mulish;
    font-size: 4rem;
    color: v.$color14;
    background: url('../images/common/icon/icon_phone02.svg') no-repeat;
    background-position: top 6px left;
    background-size: 40px 40px;
    padding-left: 52px;
    position: relative;
    left: -4px;
    transition: all 0.3s ease-out;
    cursor: pointer;

    @include r.hover {
      color: v.$color5;
    }

    @include r.max-w(1240px) {
      left: 0;
      font-size: 3.4rem;
      background-size: 35px 35px;
      background-position: center left;
    }

    @include r.sp {
      font-size: 2.6rem;
      background-size: 32px 32px;
      padding-left: 40px;
    }
  }

  &__fax {
    @include r.Mulish;
    font-size: 2.8rem;
    color: v.$color14;
    display: flex;
    align-items: center;
    margin-top: 4px;
    transition: all 0.3s ease-out;
    cursor: pointer;

    @include r.hover {
      color: v.$color5;
    }

    @include r.max-w(1240px) {
      font-size: 2.4rem;
    }

    &::before {
      content: 'FAX';
      font-size: 1.6rem;
      font-weight: bold;
      @include r.Mulish;
      color: v.$color14;
      margin: 0 14px 0 2px;

      @include r.sp {
        font-size: 1.4rem;
        margin: 0 12px 0 2px;
      }
    }

    @include r.sp {
      font-size: 2rem;
      margin-top: 6px;
    }
  }
}

.c-footer {
  &__inner {
    position: relative;
    z-index: 9;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-height: 760px;
      background: v.$color9;
      z-index: -1;

      @include r.sp {
        bottom: 0;
        height: calc(100% - 150px);
        max-height: 100%;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    padding: 82px 0 101px;

    @include r.max-w(1240px) {
      padding: 41px 0 50px;
    }

    @include r.sp {
      display: block;
      padding: 41px 0 39px;
    }
  }

  &__info {
    padding: 0 82px 0 0;
    margin: 0 88px 0 0;
    border-right: 1px solid v.$color15;

    @include r.max-w(1075px) {
      margin: 0 40px 0 0;
      padding: 0 40px 0 0;
    }

    @include r.sp {
      margin: 0 0 23px;
      padding: 0 0 29px;
      border-right: none;
      border-bottom: 1px solid v.$color15;
    }
  }

  &__logo {
    max-width: 382px;
    margin-bottom: 32px;
    @include r.sp {
      max-width: 304px;
      margin-bottom: 25px;
    }
    a {
      transition: none;
      img {
        transition: none;
      }
    }
  }

  &__inf {
    &item {
      & + .c-footer__infitem {
        margin-top: 15px;

        @include r.sp {
          margin-top: 11px;
        }
      }
    }

    &title {
      font-size: 1.8rem;
      font-weight: bold;
      color: v.$color5;
      @include r.YuGothic;
    }

    &text {
      font-size: 1.4rem;
      line-height: 150%;
      @include r.YuGothic;
      font-weight: 500;
      color: v.$color14;
      margin-top: 4px;

      @include r.sp {
        margin-top: 2px;
      }

      @include r.pc {
        a[href^='tel:'] {
          pointer-events: none;
        }
      }
    }
  }

  &__nav {
    display: flex;
    position: relative;
    top: 8px;
    @include r.max-w(1075px) {
      min-width: fit-content;
    }
    @include r.sp {
      display: block;
    }
    &list {
      & + & {
        margin-left: 30px;

        @include r.max-w(1240px) {
          margin-left: 30px;
        }

        @include r.sp {
          margin-left: 0;
          margin-top: 17px;
        }
      }

      &:last-child {
        margin-left: 39px;

        @include r.max-w(1240px) {
          margin-left: 30px;
        }

        @include r.sp {
          margin-left: 0;
        }
      }
    }
    &item {
      & + & {
        margin-top: 16px;
      }
    }
    &link {
      font-size: 1.6rem;
      @include r.YuGothic;
      font-weight: bold;
      color: v.$color14;

      @include r.sp {
        display: block;
      }

      @include r.hover {
        color: v.$color5;
      }

      &.is-navsub {
        @include r.sp {
          position: relative;

          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }

          &::before {
            right: 19px;
            width: 16px;
            height: 2px;
            border-top: 2px solid v.$color6;
          }

          &::after {
            right: 26px;
            width: 2px;
            height: 16px;
            border-left: 2px solid v.$color6;
            transition: all 0.3s ease-out;
            opacity: 1;
            visibility: visible;
          }

          &.is-active {
            &::after {
              opacity: 0;
              visibility: hidden;
            }
          }
        }
      }
    }

    &sub {
      margin-top: 15px;
      padding-bottom: 5px;
      @include r.sp {
        margin-top: 0;
        padding-bottom: 4px;
        padding-left: 8px;
        display: none;
        position: relative;
        top: 5px;
      }
      &item {
        & + & {
          margin-top: 3px;
        }
      }
      &link {
        @include r.YuGothic;
        font-size: 1.6rem;
        font-weight: 500;
        color: v.$color14;
        background: url('../images/common/icon/icon_arrow_right.svg') no-repeat;
        background-position: top 5px left 2px !important;
        background-size: 9px 12px !important;
        padding-left: 19px;

        @include r.hover {
          color: v.$color5;
          background: url('../images/common/icon/icon_arrow_right_blue.svg') no-repeat;
        }

        @include r.sp {
          padding-left: 18px;
        }
        &[target=_blank]{
          display: inline-flex;
          align-items: center;
          gap: 9px;
          &:after{
            content: "";
            width: 1rem;
            height: 1rem;
            background-image: url(/wp-content/themes/wp/assets/images/common/icon/icon_blank_blue.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }

  &__copyright {
    font-size: 1.1rem;
    @include r.Mulish;
    font-weight: 500;
    color: #fff;
    background: v.$color4;
    text-align: center;
    padding: 13px 0;

    @include r.sp {
      padding: 8px 0;
    }
  }
}
