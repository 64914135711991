@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
Box
------------------------------------------------------------*/
.c-newsbox {
  background: #fff;
  border-radius: 50px;
  font-size: 1.2rem;
  padding: 8px 28px;
  display: flex;
  align-items: center;
  max-width: 570px;

  @include r.sp {
    padding: 8px 19px;
  }

  &__title {
    font-weight: bold;
    line-height: 22px;
    border-right: 2px solid v.$color5;
    padding-right: 19px;
    margin-right: 17px;
    flex-shrink: 0;

    @include r.sp {
      padding-right: 10px;
      margin-right: 11px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    max-width: calc(100% - 78px);

    @include r.sp {
      max-width: calc(100% - 70px);
    }
    @include r.hover {
      .c-newsbox__text {
        color: v.$color5;
      }

      .c-newsbox__date {
        color: v.$color5;
      }
    }
  }

  &__date {
    @include r.Mulish;
    font-weight: bold;
    margin-right: 15px;
    transition: 0.3s all ease-in-out;
    @include r.sp {
      margin-right: 12px;
    }
  }

  &__text {
    font-weight: 300;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    transition: 0.3s all ease-in-out;
    color: v.$color1;
  }
}

/*------------------------------------------------------------
------------------------------------------------------------*/
.c-box1 {
  width: 440px;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 17px 20px 33px;

  @include r.max-w(1240px) {
    padding: 20px;
  }

  @include r.sp {
    padding: 9px 40px 18px;
  }

  &__title {
    font-size: 2.8rem;
    line-height: 2;
    font-weight: bold;
    color: v.$color5;
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 3px;

    @include r.max-w(1240px) {
      font-size: 2rem;
      line-height: 1.4;
      margin-bottom: 2px;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    margin: 0 -10px;
  }

  &__item {
    padding: 0 10px;
    position: relative;

    &:nth-child(1) {
      left: 4px;
    }

    &:nth-child(2) {
      left: -9px;
    }

    &:nth-child(3) {
      left: -9px;
    }
  }
}
