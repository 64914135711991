@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
title
------------------------------------------------------------*/
.c-title01 {
  position: relative;
  &__eng {
    position: relative;
    @include r.Mulish;
    font-size: 7.5rem;
    line-height: 1;
    color: v.$color5;
    overflow: hidden;
    font-weight: bold;
    // background: v.$color13;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    letter-spacing: -0.02em;
    white-space: nowrap;
    @include r.max-w(1240px) {
      font-size: 5.6rem;
    }

    @include r.sp {
      font-size: 4.6rem;
      letter-spacing: -0.03em;
    }
  }
  &__jap {
    display: block;
    font-size: 2.4rem;
    color: v.$color1;
    font-weight: bold;
    position: relative;
    padding-left: 22px;
    margin: 2px 0 0 34px;

    @include r.max-w(1240px) {
      font-size: 2rem;
    }

    @include r.sp {
      font-size: 2rem;
      margin: -3px 0 0 24px;
      padding-left: 16px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 21px;
      left: 4px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: v.$color5;

      @include r.max-w(1240px) {
        top: 50%;
        transform: translateY(-50%);
      }

      @include r.sp {
        width: 4px;
        height: 4px;
      }
    }
  }

  &.is-style01 {
    .c-title01 {
      &__eng {
        background: v.$color18;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }
  &.is-style02 {
    .c-title01 {
      &__eng {
        font-size: 8.6rem;
        margin-left: -6px;
        letter-spacing: -0.02em;
        line-height: 1.2;
        @include r.sp {
          font-size: 5rem;
          margin-left: -4px;
        }
      }
      &__jap {
        font-size: 2.6rem;
        line-height: 2;
        letter-spacing: 0.05em;
        margin-top: -1px;
        @include r.sp {
          font-size: 1.8rem;
          margin: 0 0 0 18px;
          padding-left: 16px;
          letter-spacing: 0;
        }
      }
    }
  }
}

/*------------------------------------------------------------
c-title02
------------------------------------------------------------*/
.c-title02 {
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 62px;
  color: v.$color5;
  letter-spacing: 0.18em;
  font-feature-settings: 'palt';

  @include r.mq(xlless) {
    font-size: 3rem;
    line-height: 46px;
  }
  @include r.mq(mdless) {
    font-size: 2.6rem;
    line-height: 43px;
    letter-spacing: 3.1px;
  }

  @include r.max-w(374px) {
    font-size: 2.2rem;
  }

  &--size2 {
    font-size: 4rem;
    line-height: 2;
    letter-spacing: 0.1em;
    @include r.sp {
      font-size: 2.6rem;
      letter-spacing: normal;
    }

    @include r.max-w(374px) {
      font-size: 2.3rem;
    }
  }
}

/*------------------------------------------------------------
c-title03
------------------------------------------------------------*/
.c-title03 {
  @include r.Mulish;
  font-weight: 300;
  font-size: 15rem;
  letter-spacing: -0.02em;
  background: v.$color24;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  opacity: 0.6;
  @include r.mq(xlless) {
    font-size: 10rem;
  }
  @include r.mq(mdless) {
    font-size: 7rem;
  }

  @include r.max-w(374px) {
    font-size: 5rem;
  }
}

/*
------------------------------------------------------------*/
.c-title04 {
  font-size: 1.4rem;
  line-height: 2;
  color: v.$color1;
  font-weight: 600;
  letter-spacing: 0.08em;
  position: relative;
  padding-left: 15px;

  @include r.sp {
    padding-left: 16px;
  }

  &::before {
    content: '';
    width: 7px;
    height: 7px;
    border: 1.5px solid v.$color6;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
}

.c-title05 {
  &__jp1 {
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.1em;
    color: #0066ff;
    display: block;
    @include r.sp {
      font-size: 2.6rem;
    }
  }
  &__jp2 {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: 0.05em;
    color: #0066ff;
    display: block;
    margin-top: 5px;
    @include r.sp {
      margin-top: 3px;
    }
  }
}

/*------------------------------------------------------------
c-title06
------------------------------------------------------------*/
.c-title06 {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &::before {
    content: '';
    background: v.$color6;
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 50%;
    margin-top: 22px;

    @include r.sp {
      margin-top: 14px;
    }
  }

  &__txt1 {
    display: inline-block;
    margin-left: 18px;
    color: v.$color1;
    font-weight: 600;
    font-size: 2.6rem;
    line-height: 52px;
    letter-spacing: 0.1em;
    flex: 1;

    @include r.sp {
      margin-left: 8px;
      font-size: 1.8rem;
      line-height: 36px;
    }
  }
}

.c-title07 {
  &__en {
    @include r.Mulish;
    font-weight: 300;
    font-size: 15rem;
    letter-spacing: -0.02em;
    background: v.$color24;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    opacity: 0.6;
    display: block;
    @include r.mq(xlless) {
      font-size: 10rem;
    }
    @include r.mq(mdless) {
      font-size: 7rem;
    }

    @include r.max-w(374px) {
      font-size: 5rem;
    }
  }
  &__jp {
    font-weight: 600;
    font-size: 3.6rem;
    line-height: 62px;
    color: v.$color5;
    letter-spacing: 0.18em;
    font-feature-settings: 'palt';
    display: block;
    @include r.mq(xlless) {
      font-size: 3rem;
      line-height: 46px;
    }
    @include r.mq(mdless) {
      font-size: 2.6rem;
      line-height: 43px;
      letter-spacing: 3.1px;
    }

    @include r.max-w(374px) {
      font-size: 2.2rem;
    }
  }
}
