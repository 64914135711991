@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
breadcrumb
------------------------------------------------------------*/
.c-breadcrumb {
  padding: 0 50px;
  margin: 0 0 66px;
  line-height: 1.2;
  @include r.sp {
    padding: 0 20px;
    margin: 0 0 40px;
  }
  &__list {
    display: inline;
  }

  &__item {
    display: inline;
    &:not(:last-child) {
      &::after {
        content: '';
        display: inline-block;
        width: 9px;
        height: 12px;
        background: url('../images/common/icon/icon_arrow_right_gray.svg')
          no-repeat center;
        background-size: 9px 12px;
        margin: 0 15px 0 14px;

        @include r.sp {
          width: 6px;
          height: 10px;
          background-size: 6px 10px;
          margin: 0 6px 0 3px;
        }
      }
    }
    a,
    span {
      font-size: 1.4rem;
      font-weight: bold;
      color: v.$color1;

      @include r.sp {
        font-size: 1.1rem;
      }
    }

    a {
      @include r.hover {
        color: v.$color5;
      }
    }

    span {
      color: v.$color5;
    }
  }
}
