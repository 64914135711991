@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
list
------------------------------------------------------------*/
.c-list1 {
  $root: &;

  //----------------------------------------------------------
  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 0;
    @include r.sp {
      padding: 0;
      border-top: 1px solid v.$color22;
      padding-bottom: 21px;
    }

    &:last-child {
      @include r.sp {
        border-bottom: 1px solid v.$color22;
      }

      #{$root}__detail {
        border-bottom: 1px solid v.$color22;
        @include r.sp {
          border-bottom: none;
        }
      }
    }
  }

  //----------------------------------------------------------
  &__detail {
    width: calc(100% - 208px);
    display: flex;
    justify-content: space-between;
    border-top: 1px solid v.$color22;
    padding: 22px 15px 20.5px;
    @include r.max-w(1240px) {
      width: calc(100% - 155px);
      padding: 20px 10px 18.5px;
    }
    @include r.sp {
      width: 100%;
      display: block;
      padding: 14px 20px 15px;
    }
  }

  //----------------------------------------------------------
  &__info {
    width: 16%;
    @include r.sp {
      width: 100%;
    }
  }

  //----------------------------------------------------------
  &__ttl {
    font-size: 1.8rem;
    line-height: 1.4;
    color: v.$color1;
    font-weight: 600;
    margin: 2px 0 -1px;
    @include r.max-w(1240px) {
      font-size: calc((18 / 1240) * 100vw);
    }
    @include r.sp {
      font-size: 1.6rem;
      margin: 0;
    }
  }

  //----------------------------------------------------------
  &__txt {
    font-size: 1rem;
    line-height: 1.3;
    color: v.$color23;
    @include r.max-w(1240px) {
      font-size: calc((10 / 1240) * 100vw);
    }
    @include r.sp {
      font-size: 1rem;
      margin: 4px 0 0;
    }
  }

  //----------------------------------------------------------
  &__address {
    width: 28%;
    font-size: 1.4rem;
    line-height: 1.3;
    color: v.$color1;
    @include r.max-w(1240px) {
      font-size: calc((14 / 1240) * 100vw);
    }
    @include r.sp {
      width: 100%;
      font-size: 1.4rem;
      margin: 11px 0 0;
    }
  }

  //----------------------------------------------------------
  &__phone {
    width: 17.5%;
    @include r.sp {
      width: 100%;
      margin: 7px 0 0;
    }
  }

  //----------------------------------------------------------
  &__number {
    font-size: 1.4rem;
    line-height: 1.3;
    color: v.$color1;
    @include r.max-w(1240px) {
      font-size: calc((14 / 1240) * 100vw);
    }
    @include r.sp {
      font-size: 1.4rem;
    }

    a {
      &[href^='tel:'] {
        pointer-events: none;
        @include r.pad {
          pointer-events: all;
        }
      }
    }

    &.fax {
      pointer-events: none;
    }
  }

  //----------------------------------------------------------
  &__mail {
    width: 33.9%;
    font-size: 1.4rem;
    line-height: 1.3;
    color: v.$color1;
    @include r.max-w(1240px) {
      font-size: calc((14 / 1240) * 100vw);
    }
    @include r.sp {
      width: 100%;
      font-size: 1.4rem;
    }
  }

  //----------------------------------------------------------
  &__action {
    width: 188px;
    @include r.max-w(1240px) {
      width: 140px;
    }
    @include r.sp {
      width: 100%;
      display: flex;
    }

    .c-btn02 {
      @include r.sp {
        width: calc(50% - 5px);
      }

      &__icon {
        @include r.max-w(1240px) {
          width: calc((20 / 1240) * 100vw);
          height: calc((20 / 1240) * 100vw);
        }
        @include r.sp {
          width: 20px;
          height: 20px;
        }
      }

      &__txt {
        @include r.max-w(1240px) {
          font-size: calc((14 / 1240) * 100vw);
        }
        @include r.sp {
          font-size: 1.4rem;
        }
      }

      &:last-child {
        margin: 3px 0 0;
        @include r.sp {
          margin: 0 0 0 10px;
        }
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-list2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
  $root: &;
  @include r.sp {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px 7px;
  }

  //----------------------------------------------------------
  &__item {
  }

  //----------------------------------------------------------
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    @include r.hover {
      transform: scale(0.98);

      #{$root}__img {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  //----------------------------------------------------------
  &__img {
    position: relative;
    padding-top: 39.15%;
    @include r.sp {
      padding-top: 48.8%;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba($color: v.$color5, $alpha: 0.65);
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.3s ease-out;
    }
  }

  //----------------------------------------------------------
  &__info {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    padding: 0 20px;
    @include r.max-w(1380px) {
      padding: calc((20 / 1380) * 100vw);
    }
    @include r.sp {
      padding: 0 9px;
    }
  }

  //----------------------------------------------------------
  &__ttl {
    text-align: center;
    font-size: 2rem;
    line-height: 1.5;
    color: #fff;
    font-weight: 600;
    position: relative;
    padding: 0 15px;
    @include r.max-w(1380px) {
      font-size: calc((20 / 1380) * 100vw);
      padding: 0 calc((15 / 1380) * 100vw);
    }
    @include r.sp {
      font-size: 1.4rem;
      padding: 0 10px;
    }
    @include r.max-w(374px) {
      font-size: 1.2rem;
      padding: 0 15px 0 5px;
    }

    &::after {
      content: '';
      width: 8px;
      height: 12px;
      background: url(p.$path + 'images/common/icon/icon_arrow_right_white.svg')
        no-repeat center / cover;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      @include r.max-w(1380px) {
        width: calc((8 / 1380) * 100vw);
        height: calc((12 / 1380) * 100vw);
      }
      @include r.sp {
        width: 8px;
        height: 12px;
      }
    }
  }
}

.c-list3 {
  &__item {
    border-top: 1px solid #ededed;
    &:last-of-type {
      border-bottom: 1px solid #ededed;
    }
  }
  &__ttl {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #292929;
    @include r.sp {
      font-size: 1.6rem;
      line-height: 22px;
    }
  }
  &__txt1 {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #292929;
    @include r.sp {
      font-size: 1.1rem;
      line-height: 16px;
    }
  }
  &__box {
    width: 373px;
    flex-shrink: 0;
    margin-right: 10px;
    @include r.sp {
      width: 100%;
      margin-right: 0;
    }
  }
  &__link {
    display: inline-flex;
    padding: 22px 32px 16px;
    @include r.sp {
      flex-direction: column;
      padding: 15px 17px;
    }
  }
  &__listcat {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    @include r.sp {
      margin-top: 10px;
    }
  }
  &__listcatitem {
    background: #0066ff;
    border-radius: 50px;
    font-weight: 600;
    font-size: 11px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #fff;
    padding: 0 13px;
    @supports (-webkit-hyphens: none) {
      @include r.sp {
        padding: 1px 13px 0;
        line-height: 20px;
      }
      @include r.max-w(374px) {
        padding: 1px 10px 0;
      }
    }
    @include r.max-w(374px) {
      padding: 0 10px;
    }
  }
}

/*
------------------------------------------------------------*/
.c-list4 {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 3px;
  width: calc(100% - 40px);
  @include r.sp {
    padding: 0;
    width: 100%;
  }

  &__title {
    background: v.$color5;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 24px;
    padding: 8px 43px;
    color: v.$color2;
    display: block;

    @include r.sp {
      padding: 14px 20px 12px 20px;
      letter-spacing: 0.05em;
    }
  }

  &__list {
    margin-top: 5px;
    column-count: 2;
    column-gap: 4px;
    @include r.mq(xlless) {
      margin-top: 2px;
    }
    @include r.sp {
      column-count: auto;
      column-gap: normal;
      margin-top: 2px;
    }
  }

  &__item {
    margin-bottom: 2px;
    padding: 5px 37px 5px 28px;
    background: v.$color9;
    display: flex;
    align-items: flex-start;
    // &:nth-of-type(n + 16) {
    //   @include r.pc {
    //     display: flex !important;
    //   }
    //   @include r.sp {
    //     display: none;
    //   }
    // }
    &:last-child {
      margin-bottom: 0;
    }

    @include r.mq(xlless) {
      padding: 5px 20px 5px 20px;
    }

    @include r.sp {
      padding: 7px 22px 15px;
    }
  }

  &__num {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 24px;
    text-align: right;
    color: v.$color14;
    display: inline-block;
    width: 31px;
    word-break: break-all;

    @include r.mq(xlless) {
      font-size: 1.2rem;
      width: 17px;
    }
    @include r.sp {
      font-size: 1.4rem;
      line-height: 25px;
      width: 31px;
    }
  }

  &__detail {
    margin-left: 41px;
    display: flex;
    align-items: flex-start;
    width: 100%;

    @include r.mq(xlless) {
      margin-left: 15px;
    }

    @include r.sp {
      margin-left: 15px;
      flex-direction: column;
    }
  }

  &__name {
    max-width: 288px;
    width: 100%;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 24px;
    color: v.$color14;

    @include r.mq(xlless) {
      font-size: 1.2rem;
      width: 53.6%;
    }

    @include r.sp {
      font-size: 1.4rem;
      line-height: 25px;
      letter-spacing: 0;
      max-width: 100%;
      width: 100%;
    }
  }

  &__price {
    margin-left: 20px;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 24px;
    text-align: right;
    color: v.$color14;
    flex: 1;

    @include r.mq(xlless) {
      font-size: 1.2rem;
    }

    @include r.sp {
      flex: auto;
      margin-left: 0;
      letter-spacing: 1px;
    }
  }

  .c-btn01 {
    margin-top: 36px;
    &__link {
      &::after {
        background: url(p.$path + 'images/common/icon/icon_plus.svg') no-repeat
          center;
        width: 13px;
        height: 13px;
      }
    }
  }
}
