@use '../../settings/_variable' as v;
@use '../../settings/_mixin' as r;
@use '../../settings/_path' as p;

.p-top {
  overflow: hidden;
  &__box {
    position: relative;
  }
  &1 {
    padding: 120px 0 80px;
    @include r.sp {
      padding: 50px 0;
    }
  }

  &2 {
    padding: 92px 0 31px;
    @include r.sp {
      padding: 51px 0;
    }
  }

  &3 {
    padding: 82px 0 123px;
    position: relative;
    @include r.sp {
      padding: 46px 0 70px;
    }

    &::after {
      content: '';
      width: 1840px;
      height: 1840px;
      background: linear-gradient(180deg, v.$color29 0%, v.$color30 100%);
      border-radius: 50%;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .c-imgtext02 {
      max-width: 1240px;
      margin: 65px auto 0;
      @include r.sp {
        margin: 31px auto 0;
      }
    }
  }

  &4 {
    &__banner {
      width: calc(50% + 620px);
      max-width: calc(100% - 40px);
      margin: 0 0 58px;
      @include r.sp {
        max-width: 100%;
        width: calc(100% - 20px);
        margin: 0 0 36px;
      }
    }
  }

  &5 {
    margin-top: 125px;
    @include r.sp {
      margin-top: 86px;
    }

    .c-btn01 {
      margin-top: 34px;
    }
  }

  &6 {
    padding: 119px 0 115px;
    @include r.sp {
      padding: 89px 0 95px;
    }

    .c-title01 {
      padding: 0 0 0 24px;
      @include r.sp {
        padding: 0;
      }
    }

    .c-navi2 {
      max-width: 1240px;
      margin: 38px auto 0;
      @include r.sp {
        margin: 34px auto 0;
      }
    }
  }
}
