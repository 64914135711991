@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

.c-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 22px 26px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 0.3s;
  pointer-events: none;
  $root: &;
  @include r.max-w(1380px) {
    padding: 22px 20px;
  }
  @include r.pad {
    padding: 20px;
  }

  &.is-dark {
    #{$root}__logo {
      .white {
        opacity: 0;
      }

      .dark {
        opacity: 1;
      }
    }

    #{$root}__menu__txt {
      color: v.$color1;
    }
  }

  &.is-hidden {
    transform: translate3d(0, -120%, 0);
    transition: all 0.3s;
  }
  &.is-hidden2 {
    .c-header {
      &__menu {
        @include r.pc {
          opacity: 0;
          pointer-events: none;
          visibility: hidden;
        }
      }
    }
  }

  //----------------------------------------------------------
  &__logo {
    width: 89px;
    padding-left: 11px;
    pointer-events: all;

    @include r.max-w(1380px) {
      width: calc((69 / 1380) * 100vw);
    }
    @include r.pad {
      width: 51px;
      padding-left: 0;
    }

    a {
      display: block;
      position: relative;
    }

    img {
      width: 100%;
    }

    .dark {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 0.3s;
    }
  }

  //----------------------------------------------------------
  &__nav {
    width: calc(100% - 138px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include r.max-w(1380px) {
      flex: 1;
      padding-left: calc((20 / 1380) * 100vw);
    }
    @include r.pad {
      flex: none;
      width: 196px;
      padding-left: 0;
    }
  }

  //----------------------------------------------------------
  &__menu {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include r.pad {
      display: none;
    }

    &__item {
      position: relative;
      pointer-events: all;
      &:not(:last-child) {
        margin-right: 22px;
        @include r.max-w(1380px) {
          margin-right: calc((22 / 1380) * 100vw);
        }
      }

      &.is-sub {
        #{$root}__menu__txt {
          position: relative;
          padding-right: 17px;
          z-index: 2;

          &::after {
            content: '';
            width: 11px;
            height: 7px;
            background: url(p.$path + 'images/common/icon/icon_arrow01.svg')
              no-repeat center / cover;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
          }
        }
      }

      @include r.hover {
        #{$root}__menu__txt {
          color: v.$color5;
        }

        #{$root}__submenu {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          visibility: visible;
        }
      }
    }

    &__txt {
      display: block;
      font-size: 1.3rem;
      line-height: 1.55;
      color: v.$color2;
      font-weight: bold;
      @include r.YuGothic;
      @include r.max-w(1380px) {
        font-size: calc((13 / 1380) * 100vw);
      }
    }
  }

  //----------------------------------------------------------
  &__submenu {
    width: 170px;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    transition: opacity 0.35s cubic-bezier(0.26, 0.06, 0, 1),
      transform 1s cubic-bezier(0.18, 0.06, 0.23, 1);
    padding-top: 29px;
    visibility: hidden;
    @include r.max-w(1380px) {
      width: calc((170 / 1380) * 100vw);
    }
    &--1{
      width: 228px;
    }
    &--5{
      width: 194px;
    }

    &__list {
      width: 100%;
      background: v.$color2;
      border-radius: 5px;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      padding: 26px 20px;
      @include r.max-w(1380px) {
        padding: calc((26 / 1380) * 100vw) calc((20 / 1380) * 100vw);
      }
    }

    &__item {
      font-size: 0;

      &:not(:last-child) {
        margin: 0 0 9px;
      }
    }

    &__txt {
      display: inline-block;
      font-size: 1.3rem;
      line-height: 1.5;
      color: v.$color1;
      font-weight: 600;
      position: relative;
      padding-left: 12px;
      @include r.max-w(1380px) {
        font-size: calc((13 / 1380) * 100vw);
      }

      &::before {
        content: '';
        width: 6px;
        height: 6px;
        background: v.$color5;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transition: all 0.2s;
        transform: translate(0, -50%);
        @include r.max-w(1380px) {
          width: 4px;
          height: 4px;
        }
      }

      @include r.hover {
        &::before {
          transform: translate(0, -50%) scale(1.4);
        }
      }
      &[target=_blank]{
        display: inline-flex;
        align-items: center;
        gap: 4px;
        &:after{
          content: "";
          width: 1rem;
          height: 1rem;
          background-image: url(/wp-content/themes/wp/assets/images/common/icon/icon_blank_blue.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }

  //----------------------------------------------------------
  &__action {
    display: flex;
    align-items: center;
    pointer-events: all;
  }

  //----------------------------------------------------------
  &__phone {
    width: 344px;
    height: 80px;
    @include r.max-w(1380px) {
      width: calc((360 / 1380) * 100vw);
      height: 60px;
    }
    @include r.pad {
      width: 60px;
      height: 60px;
    }

    &__btn {
      width: 100%;
      height: 100%;
      background: v.$color4;
      border-radius: 5px;
      display: none;
      @include r.pad {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        display: block;
        text-align: center;
        font-size: 1.1rem;
        line-height: 1.5;
        color: v.$color2;
        font-weight: 600;
        position: relative;
        padding-top: 28px;

        &::before {
          content: '';
          width: 23px;
          height: 23px;
          background: url(p.$path + 'images/common/icon/icon_phone01.svg')
            no-repeat center / cover;
          position: absolute;
          top: 4px;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }

    &__close {
      display: none;
      @include r.pad {
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }

    &__wrap {
      width: 100%;
      height: 100%;
      @include r.pad {
        background: rgba($color: v.$color4, $alpha: 0.9);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        padding: 90px 20px 80px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-out;
      }

      &.is-open {
        opacity: 1;
        visibility: visible;
      }
    }

    &__list {
      width: 100%;
      height: 100%;
      background: v.$color4;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      @include r.pad {
        height: auto;
        display: block;
        background: transparent;
      }

      &::before {
        content: '';
        width: 2px;
        height: calc(100% - 40px);
        background: v.$color12;
        border-radius: 5px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include r.max-w(1380px) {
          height: calc(100% - 20px);
        }
        @include r.pad {
          display: none;
        }
      }
    }

    &__item {
      width: 50%;
      padding: 3px 15px 0 19px;
      transition: all 0.3s ease-out;
      position: relative;
      @include r.max-w(1380px) {
        padding: 3px 10px 0 14px;
      }
      @include r.pad {
        width: 100%;
        text-align: center;
        padding: 48px 10px 40px;
      }

      &:not(:last-child) {
        @include r.pad {
          border-bottom: 1px solid v.$color2;
        }
      }

      @include r.hover {
        transform: scale(0.9);
      }
    }

    &__txt {
      font-size: 1.2rem;
      line-height: 1.5;
      color: v.$color2;
      font-weight: 700;
      @include r.YuGothic;
      @include r.max-w(1380px) {
        font-size: calc((12 / 1380) * 100vw);
      }
      @include r.pad {
        text-align: center;
        font-size: 2.2rem;
        margin: 0 0 8px;
      }
    }

    &__number {
      display: block;
      font-size: 1.8rem;
      line-height: 1.3;
      color: v.$color2;
      font-weight: 600;
      @include r.Mulish;
      letter-spacing: -0.04em;
      margin-top: -1px;
      @include r.max-w(1380px) {
        font-size: calc((18 / 1380) * 100vw);
      }
      @include r.pad {
        display: inline-block;
        font-size: 3.4rem;
        font-weight: 500;
        letter-spacing: 0;
        position: relative;
      }
      @include r.max-w(374px) {
        font-size: 3rem;
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      span {
        display: inline-block;
        position: relative;
        padding-left: 20px;
        @include r.max-w(1380px) {
          padding-left: calc((20 / 1380) * 100vw);
        }
        @include r.pad {
          padding-left: 55px;
        }
        @include r.max-w(374px) {
          padding-left: 40px;
        }

        &::before {
          content: '';
          width: 17px;
          height: 17px;
          background: url(p.$path + 'images/common/icon/icon_phone01.svg')
            no-repeat center / cover;
          position: absolute;
          top: 4px;
          left: 0;
          @include r.max-w(1380px) {
            width: calc((16 / 1380) * 100vw);
            height: calc((16 / 1380) * 100vw);
            top: calc((4 / 1380) * 100vw);
          }
          @include r.pad {
            background: url(p.$path + 'images/common/icon/icon_phone02_white.svg')
              no-repeat center / cover;
            width: 42px;
            height: 42px;
          }
          @include r.max-w(374px) {
            width: 34px;
            height: 34px;
          }
        }
      }
    }
  }

  //----------------------------------------------------------
  &__contact {
    width: 80px;
    height: 80px;
    background: v.$color5;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    line-height: 1.5;
    color: v.$color2;
    font-weight: 700;
    @include r.YuGothic;
    margin: 0 15px;
    transition: all 0.3s ease-out;
    position: relative;
    @include r.max-w(1380px) {
      width: 60px;
      height: 60px;
      font-size: 1rem;
      margin: 0 8px;
    }
    @include r.pad {
      font-size: 1.1rem;
    }

    a {
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    span {
      display: block;
      position: relative;
      padding-top: 32px;
      @include r.pad {
        padding-top: 28px;
      }

      &::before {
        content: '';
        width: 30px;
        height: 30px;
        background: url(p.$path + 'images/common/icon/icon_mail01.svg')
          no-repeat center / cover;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        @include r.pad {
          width: 26px;
          height: 26px;
          top: 2px;
        }
      }
    }

    @include r.hover {
      transform: scale(0.9);
    }
  }

  //----------------------------------------------------------
  &__hamburge {
    width: 80px;
    height: 80px;
    background: v.$color2;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.3s ease-out;
    cursor: pointer;
    @include r.max-w(1380px) {
      width: 60px;
      height: 60px;
    }

    &__icon {
      width: 24px;
      height: 18px;
      margin: 4px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @include r.max-w(1380px) {
        width: 22px;
        height: 16px;
      }

      span {
        display: block;
        width: 100%;
        height: 2px;
        background: v.$color1;
        border-radius: 5px;
        transition: all 0.3s ease-out;

        &::before {
          content: '';
          width: 100%;
          height: 2px;
          background: v.$color1;
          position: absolute;
          top: 0;
          left: 0;
          transition: all 0.3s;
        }

        &::after {
          content: '';
          width: 100%;
          height: 2px;
          background: v.$color1;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: all 0.3s;
        }
      }
    }

    &__txt {
      display: block;
      width: 100%;
      font-size: 1.4rem;
      line-height: 1.3;
      color: v.$color1;
      font-weight: 700;
      @include r.YuGothic;
      margin: 4px 0 0;
      @include r.max-w(1380px) {
        font-size: 1rem;
      }
      @include r.pad {
        font-size: 1.1rem;
        margin: 2px 0 0;
      }
    }

    @include r.hover {
      transform: scale(0.9);
      #{$root}__hamburge__icon {
        span {
          &::before {
            top: 2px;
          }

          &::after {
            bottom: 2px;
          }
        }
      }
    }

    &.is-hidden {
      opacity: 0;
    }
  }

  //----------------------------------------------------------
  &__subnav {
    width: 100%;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    overflow-y: auto;
    transition: 0s height;
    transition-delay: 1s;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba($color: v.$color4, $alpha: 0.3);
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-out;
    }

    &.is-open {
      height: 100%;
      transition: 0s height;
      pointer-events: all;
      &::after {
        opacity: 1;
        visibility: visible;
      }

      #{$root}__close {
        &__icon {
          &::before {
            transform: rotate(45deg) scaleX(1);
            opacity: 1;
            transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0.6s,
              transform 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0.6s;
          }

          &::after {
            transform: rotate(45deg) scaleY(1);
            opacity: 1;
            transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0.6s,
              transform 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0.8s;
          }
        }

        &__txt {
          opacity: 1;
          transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0.6s,
            transform 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0.6s;
        }
      }

      #{$root}__wrap {
        &::after {
          transform: scale(1);
          opacity: 1;
          transition: opacity 0.4s 0s,
            transform 0.6s cubic-bezier(0.87, 0, 0.13, 1) 0s;
        }
      }

      #{$root}__menu2__item {
        transform: translateY(0);
        opacity: 1;
        transition: opacity 0.6s, transform 0.6s;

        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            transition-delay: #{$i * 0.1 + 0.2}s;
          }
        }
        &:nth-child(1) {
          margin-right: 5%;
          @include r.pad {
            margin-right: 0;
          }
        }
        &:nth-child(2) {
          margin-right: 4%;
          @include r.pad {
            margin-right: 0;
          }
        }
        &:nth-child(3) {
          margin-right: 7%;
          @include r.pad {
            margin-right: 0;
          }
        }
        &:nth-child(4) {
          margin-right: 5.5%;
          @include r.pad {
            margin-right: 0;
          }
        }
      }

      #{$root}__contact2 {
        transform: translateY(0);
        opacity: 1;
        transition: opacity 0.6s, transform 0.6s;
        transition-delay: 0.8s;
      }
      #{$root}__recruit {
        transform: translateY(0);
        opacity: 1;
        transition: opacity 0.6s, transform 0.6s;
        transition-delay: 0.9s;
      }
    }
  }

  //----------------------------------------------------------
  &__wrap {
    margin: 20px 25px;
    padding: 67px 20px 42px;
    position: relative;
    z-index: 2;
    @include r.pad {
      margin: 0;
      padding: 72px 20px 50px;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: v.$color2;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform: scale(0);
      transform-origin: right top;
      opacity: 0;
      transition: opacity 0.7s 0.5s,
        transform 0.5s cubic-bezier(0.87, 0, 0.13, 1) 0.1s;
      @include r.pad {
        border-radius: 0;
      }
    }
  }

  //----------------------------------------------------------
  &__close {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    @include r.pad {
      width: 60px;
      height: 60px;
      top: 20px;
      right: 20px;
    }

    &__icon {
      display: block;
      width: 22px;
      height: 20px;
      margin: 4px 0;
      position: relative;
      @include r.sp {
        width: 20px;
        height: 18px;
      }

      &::before {
        content: '';
        display: block;
        width: 30px;
        height: 2px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: v.$color1;
        transform: rotate(45deg) scaleX(0);
        transform-origin: left top;
        opacity: 0;
        border-radius: 1px;
        transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0s,
          transform 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0.3s;
        @include r.pad {
          width: 28px;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 2px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        background-color: v.$color1;
        transform: rotate(45deg) scaleY(0);
        transform-origin: left top;
        opacity: 0;
        border-radius: 1px;
        transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0s,
          transform 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0.3s;
        @include r.pad {
          height: 28px;
        }
      }
    }

    &__txt {
      text-align: center;
      display: block;
      font-size: 1.4rem;
      line-height: 1.3;
      color: v.$color1;
      font-weight: 700;
      @include r.YuGothic;
      margin-top: 4px;
      opacity: 0;
      transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0s,
        transform 0.3s cubic-bezier(0.6, 0.25, 0, 1) 0.3s;
      @include r.pad {
        font-size: 1.2rem;
        margin-top: 2px;
      }
    }
  }

  //----------------------------------------------------------
  &__inner {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }

  //----------------------------------------------------------
  &__sitemap {
    padding: 0 20px;
    @include r.pad {
      padding: 0 6px;
    }
  }

  //----------------------------------------------------------
  &__menu2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &__item {
      max-width: 18%;
      transform: translateY(50px);
      opacity: 0;
      transition: opacity 0.3s, transform 0.3s;
      transition-delay: 0s;
      @include r.pad {
        max-width: 100%;
        width: 100%;
      }

      &:not(:last-child) {
        margin: 0 5% 0 0;
        @include r.pad {
          margin: 0 0 18px;
        }
      }
    }

    &__box {
      &:not(:last-child) {
        margin-bottom: 32px;
        @include r.pad {
          margin-bottom: 18px;
        }
      }

      &.is-sub {
        #{$root}__ttl {
          position: relative;

          &::before {
            @include r.pad {
              content: '';
              width: 12px;
              height: 2px;
              background: v.$color6;
              border-radius: 2px;
              position: absolute;
              top: 12px;
              right: 18px;
            }
          }

          &::after {
            @include r.pad {
              content: '';
              width: 2px;
              height: 12px;
              background: v.$color6;
              border-radius: 2px;
              position: absolute;
              top: 7px;
              right: 23px;
              transition: all 0.3s ease-out;
            }
          }

          &.is-open {
            &::after {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  //----------------------------------------------------------
  &__ttl {
    word-break: keep-all;
    white-space: nowrap;
    font-size: 3.2rem;
    line-height: 1;
    color: v.$color5;
    font-weight: bold;
    @include r.YuGothic;
    @include r.max-w(1380px) {
      font-size: calc((32 / 1380) * 100vw);
    }
    @include r.pad {
      font-size: 2.6rem;
    }
  }

  //----------------------------------------------------------
  &__subttl {
    display: block;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 600;
    margin-top: 7px;
    @include r.max-w(1380px) {
      font-size: calc((12 / 1380) * 100vw);
      margin-top: calc((8 / 1380) * 100vw);
    }
    @include r.pad {
      font-size: 1.2rem;
      margin-top: 6px;
    }

    a {
      display: inline-block;
      position: relative;
      padding-left: 13px;
      color: v.$color1;
      @include r.pad {
        padding-left: 9px;
      }

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        background: v.$color5;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        left: 3px;
        @include r.max-w(1380px) {
          width: 3px;
          height: 3px;
          top: 5px;
        }
        @include r.pad {
          top: 7px;
          left: 2px;
        }
      }
      @include r.hover {
        color: v.$color5;
      }
    }
  }

  //----------------------------------------------------------
  &__info {
    padding: 9px 0 0;
    @include r.max-w(1380px) {
      padding: calc((9 / 1380) * 100vw) 0 0;
    }
    @include r.pad {
      display: none;
      padding: 9px 0 0;
    }
    @include r.mq(lgover) {
      display: block !important;
    }
  }

  //----------------------------------------------------------
  &__submenu2 {
    &__item {
      &:not(:last-child) {
        margin: 0 0 -1px;
        @include r.pad {
          margin: 0 0 2px;
        }
      }
    }

    &__txt {
      display: inline-block;
      font-size: 1.4rem;
      line-height: 1.5;
      color: v.$color1;
      font-weight: 600;
      @include r.max-w(1380px) {
        font-size: calc((14 / 1380) * 100vw);
      }
      @include r.pad {
        font-size: 1.4rem;
      }

      @include r.hover {
        color: v.$color5;
      }
      &[target=_blank]{
        display: inline-flex;
        align-items: center;
        gap: 9px;
        &:after{
          content: "";
          width: 1rem;
          height: 1rem;
          background-image: url(/wp-content/themes/wp/assets/images/common/icon/icon_blank_blue.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }

  //----------------------------------------------------------
  &__contact2 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    padding: 30px 30px 0 20px;
    margin: 31px 0 0;
    transform: translateY(50px);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transition-delay: 0s;
    @include r.pad {
      margin: 26px 0 0;
      padding: 0;
    }

    &::before {
      content: '';
      width: 100%;
      height: 2px;
      background: v.$color21;
      border-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  //----------------------------------------------------------
  &__phone2 {
    width: 52.6%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    @include r.pad {
      width: 100%;
    }

    &::after {
      content: '';
      width: 2px;
      height: 100%;
      background: v.$color21;
      position: absolute;
      top: 0;
      left: 49%;
      transform: translate(-50%, 0);
      @include r.pad {
        display: none;
      }
    }

    &__item {
      width: 42.5%;
      @include r.pad {
        width: 100%;
        padding: 22px 26px 26px;
      }
      @include r.max-w(374px) {
        padding: 22px 10px 26px;
      }

      &:not(:last-child) {
        @include r.pad {
          border-bottom: 2px solid v.$color21;
        }
      }
    }

    &__txt {
      font-size: 1.6rem;
      line-height: 1;
      color: v.$color5;
      font-weight: 600;
      margin-bottom: 12px;
      @include r.max-w(1380px) {
        font-size: calc((16 / 1380) * 100vw);
      }
      @include r.pad {
        font-size: 1.6rem;
        margin-bottom: 13px;
      }
    }

    &__number {
      display: inline-block;
      font-size: 3rem;
      line-height: 1;
      color: v.$color1;
      font-weight: 400;
      @include r.Mulish;
      position: relative;
      padding-left: 40px;
      @include r.max-w(1380px) {
        font-size: calc((30 / 1380) * 100vw);
        padding-left: 30px;
      }
      @include r.pad {
        font-size: 3rem;
        padding-left: 40px;
      }

      &::before {
        content: '';
        width: 32px;
        height: 32px;
        background: url(p.$path + 'images/common/icon/icon_phone02.svg')
          no-repeat center / cover;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        @include r.max-w(1380px) {
          width: 24px;
          height: 24px;
        }
        @include r.pad {
          width: 32px;
          height: 32px;
        }
      }

      @include r.hover {
        color: v.$color5;
      }
    }
  }

  //----------------------------------------------------------
  &__fax {
    font-size: 1.6rem;
    line-height: 1;
    color: v.$color1;
    font-weight: 400;
    @include r.Mulish;
    margin-top: -3px;
    @include r.max-w(1380px) {
      font-size: calc((16 / 1380) * 100vw);
    }
    @include r.pad {
      font-size: 1.6rem;
      margin-top: -3px;
    }

    a {
      font-size: 2.4rem;
      margin: 0 0 0 7px;
      position: relative;
      top: 3px;
      @include r.max-w(1380px) {
        font-size: calc((24 / 1380) * 100vw);
        margin: 0 0 0 calc((11 / 1380) * 100vw);
        top: calc((2 / 1380) * 100vw);
      }
      @include r.pad {
        font-size: 2.4rem;
        top: 3px;
        margin: 0 0 0 11px;
      }
    }
  }

  //----------------------------------------------------------
  &__btn {
    width: 34.8%;
    @include r.pad {
      width: calc(100% - 50px);
      margin: 5px auto 0;
    }
    @include r.max-w(374px) {
      width: calc(100% - 20px);
    }

    a {
      width: 100%;
      display: block;
      background: v.$color5;
      border-radius: 5px;
      text-align: center;
      font-size: 1.8rem;
      line-height: 1;
      color: v.$color2;
      font-weight: 600;
      padding: 23px 20px;
      @include r.max-w(1380px) {
        font-size: calc((18 / 1380) * 100vw);
      }
      @include r.pad {
        font-size: 1.6rem;
        padding: 9px 10px;
      }
      @include r.max-w(374px) {
        font-size: 1.4rem;
      }

      span {
        display: inline-block;
        position: relative;
        padding: 13px 19px 13px 49px;
        @include r.max-w(1380px) {
          padding: 13px 19px 13px 39px;
        }
        @include r.pad {
          padding: 13px 16px 13px 50px;
        }
        @include r.max-w(374px) {
          padding: 13px 16px 13px 40px;
        }

        &::before {
          content: '';
          width: 40px;
          height: 40px;
          background: url(p.$path + 'images/common/icon/icon_mail02.svg')
            no-repeat center / cover;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          @include r.max-w(1380px) {
            width: 30px;
            height: 30px;
          }
          @include r.pad {
            width: 40px;
            height: 40px;
          }
          @include r.max-w(374px) {
            width: 30px;
            height: 30px;
          }
        }

        &::after {
          content: '';
          width: 8px;
          height: 12px;
          background: url(p.$path + 'images/common/icon/icon_arrow_right_white.svg')
            no-repeat center / cover;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
        }
      }

      @include r.hover {
        transform: scale(0.9);
      }
    }
  }

  //----------------------------------------------------------
  &__recruit{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    margin-top: 55px;
    transform: translateY(50px);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transition-delay: 0s;
    @include r.sp{
      flex-direction: column;
      gap: 21px;
    }
    &__item{
      width: calc((100% - 18px) / 2);
      border-radius: 10px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      @include r.sp{
        width: 100%;
      }
      &:hover{
        @include r.pc{
          transform: scale(0.95);
        }
      }
    }
    &__link{
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
    }
    &__img{
      img{
        height: 150px;
        object-fit: cover;
        object-position: center center;
        @include r.sp{
          height: 232px;
        }
      }
      &:after{
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 102, 255, 0.5), rgba(0, 102, 255, 0.5));
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &__info{
      width: 100%;
      color: #FFF;
      display: flex;
      align-items: baseline;
      gap: 28px;
      padding: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include r.sp{
        flex-direction: column;
        gap: 11px;
        padding: 22px;
        top: 0;
        left: 0;
        transform: translateY(0);
      }
    }
    &__main{
      display: block;
      font-size: 3.5rem;
      font-weight: bold;
      line-height: 1.2;
    }
    &__sub{
      display: block;
      font-size: 1.6rem;
      font-weight: bold;
    }
    &__des{
      font-size: 1.4rem;
      font-weight: bold;
    }
    &__btn{
      position: absolute;
      bottom: 9px;
      right: 22px;
      &__txt{
        font-size: 1.2rem;
        font-weight: bold;
        color: #FFF;
        &:after{
          content: "";
          background: url(/wp-content/themes/wp/assets/images/common/icon/icon_arrow_right_white.svg) no-repeat;
          width: 9px;
          height: 12px;
          display: inline-block;
          margin-left: 11px;
          background-size: 9px 12px;
          margin-bottom: -1px;
        }
      }
    }
  }

}
