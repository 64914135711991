@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
table
------------------------------------------------------------*/
.c-table1 {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    max-width: 1030px;
    margin: 0 auto;

    tr {
        border-bottom: 1px solid v.$color26;

        @include r.sp{
            display: flex;
            flex-direction: column;
        }

        &:last-child {
            border-bottom: none;
        }

        th {
            padding: 20px 10px 21px 0;
            width: 120px;
            color: v.$color1;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 32px;
            vertical-align: top;
            text-align: left;

            @include r.sp{
                padding: 0;
                padding-top: 16px;
                line-height: 20px;
            }
        }

        td {
            padding: 20px 20px 21px 28px;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 26px;
            @include r.sp{
                padding: 0;
                padding-top: 1px;
                padding-bottom: 15px;
                line-height: 24px;
            }
        }
    }
}
