@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
mv
------------------------------------------------------------*/

.c-mv1 {
  position: relative;
  height: 100vh;
  overflow: hidden;

  @include r.sp {
    height: auto;
    padding-bottom: 18px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__list {
    width: 100%;
    height: 100%;
  }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    clip-path: inset(0 0 0 0);

    &.swiper-slide-active {
      transition: 2s cubic-bezier(0.87, 0, 0.13, 1) -webkit-clip-path;
      transition: 2s cubic-bezier(0.87, 0, 0.13, 1) clip-path;
      transition: 2s cubic-bezier(0.87, 0, 0.13, 1) clip-path,
        2s cubic-bezier(0.87, 0, 0.13, 1) -webkit-clip-path;
      opacity: 1;
      z-index: 3;
      -webkit-clip-path: inset(0 0 100% 0);
      clip-path: inset(0 0 100% 0);

      .c-mv1__img {
        transform: translate3d(0, -20%, 0);
        transition: 2s cubic-bezier(0.87, 0, 0.13, 1) transform;
      }
    }

    &.swiper-slide-next {
      opacity: 1;
      z-index: 2;

      .c-mv1__img {
        transform: translate3d(0, 0%, 0);
        transition: 2s cubic-bezier(0.87, 0, 0.13, 1) transform;
      }
    }
  }

  &__img {
    height: 100%;
    width: 100%;
    transform: translate3d(0, 20%, 0);
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 316px 106px;

    @include r.max-w(1240px) {
      padding: 200px 30px;
    }

    @include r.sp {
      padding: 198px 21px 20px;
    }
  }

  &__title {
    font-size: 5.2rem;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: 0.08em;
    color: #fff;
    position: relative;
    display: block;
    overflow: hidden;

    @include r.max-w(1240px) {
      font-size: 3.2rem;
    }

    @include r.sp {
      font-size: 2.7rem;
      line-height: 140%;
      letter-spacing: 0.05em;
    }

    @include r.max-w(374px) {
      font-size: 2rem;
    }

    span {
      display: inline-block;
      position: relative;
      transform: translate(160px, 50px);
      -webkit-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
      transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
      color: v.$color18;
      will-change: transform;
      opacity: 0;
    }
  }

  #mv_text {
    overflow: hidden;

    &.is-active {
      .c-mv1__title {
        span {
          opacity: 1;
          transform: translate(0, 0);
          color: #fff;
        }
      }
    }
  }

  &__text {
    position: relative;
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.08em;
    color: #fff;
    @include r.Mulish;
    max-width: fit-content;
    padding: 8px 6px;
    margin-top: 11px;

    @include r.max-w(1240px) {
      font-size: 1.8rem;
    }

    @include r.sp {
      font-size: 1.5rem;
      padding: 0 3px;
      margin-top: 7px;
    }
  }

  .c-newsbox {
    position: absolute;
    bottom: 23px;
    left: 30px;
    z-index: 99;

    @include r.max-w(1240px) {
      max-width: calc((100% - 100px) / 2);
    }

    @include r.sp {
      position: relative;
      max-width: 100%;
      left: 0;
      bottom: 0;
      margin: 0 20px;
    }
  }

  .c-box1 {
    position: absolute;
    bottom: -30px;
    right: 16px;
    opacity: 0;
    transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);

    @include r.max-w(1240px) {
      width: calc((100% - 100px) / 2);
      right: 30px;
      bottom: -50px;
    }

    @include r.sp {
      position: relative;
      width: calc(100% - 40px);
      right: 0;
      bottom: 0;
      margin: 12px auto 17px;
    }

    &.is-active {
      opacity: 1;
      bottom: 18px;

      @include r.sp {
        bottom: 0;
      }
    }
  }

  .c-pagescroll {
    position: absolute;
    bottom: 127px;
    left: 36px;
    min-height: 127px;
    opacity: 1;
    visibility: visible;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    z-index: 99;

    @include r.sp {
      min-height: 110px;
    }

    a {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
    }

    @include r.max-w(1240px) {
      bottom: 120px;
    }

    @include r.sp {
      width: 26px;
      left: 0;
      bottom: 0;
      position: relative;
      margin: 0 7px 0 auto;
    }

    &__txt {
      font-size: 1.4rem;
      @include r.Mulish;
      color: #fff;
      font-weight: 300;
      letter-spacing: 0.1em;
      writing-mode: vertical-lr;
      opacity: 0;
      transition: 0.5s all ease-in-out;

      @include r.sp {
        font-size: 1.1rem;
      }
    }

    &__line {
      height: 0px;
      position: relative;
      width: 1px;
      background: #fff;
      top: 8px;
      transition: 0.5s all ease-in-out;
      transition-delay: 0.5s;

      @include r.sp {
        top: 5px;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: -2px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #fff;
        opacity: 0;
        transition: 0.5s all ease-in-out;
        transition-delay: 1s;
      }
    }

    &__circle {
      position: absolute;
      bottom: -24px;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      opacity: 0;
      transition: 0.5s all ease-in-out;
      transition-delay: 1.5s;

      @include r.sp {
        width: 26px;
        height: 26px;
        bottom: -17px;
      }

      &::before,
      &::after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        top: 0;
        left: 0;
      }

      &::before {
        border: 1px solid transparent;
      }

      &::after {
        border: 0 solid transparent;
      }
    }

    &.is-hide {
      opacity: 0;
      visibility: hidden;
    }

    &.is-active {
      .c-pagescroll {
        &__txt {
          opacity: 1;
        }

        &__line {
          height: 84px;

          @include r.sp {
            height: 56px;
          }

          &::after {
            opacity: 1;
          }
        }

        &__circle {
          opacity: 1;
          &::before {
            border-top-color: #fff;
            border-right-color: #fff;
            border-bottom-color: #fff;
            transition: border-top-color 0.15s ease-in-out,
              border-right-color 0.15s ease-in-out 1.8s,
              border-bottom-color 0.15s ease-in-out 1.9s;
            transition-delay: 1.7s;
          }

          &::after {
            border-top: 1px solid #fff;
            border-left-width: 1px;
            border-right-width: 1px;
            transform: rotate(270deg);
            opacity: 1;
            transition: transform 0.4s ease-in-out 0s,
              border-left-width 0s ease-in-out 2.05s;
            transition-delay: 1.7s;
            z-index: 1;
          }
        }
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-mv2 {
  padding: 79px 0;

  @include r.sp {
    padding: 45px 0;
  }

  //----------------------------------------------------------
  &__inner {
    max-width: 1292px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    @include r.sp {
      max-width: 100%;
      padding: 0 16px;
    }
  }

  //----------------------------------------------------------
  &__ttl {
    &eng {
      // background: v.$color13;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      // background-clip: text;
      overflow: hidden;
      font-size: 8.6rem;
      line-height: 1.2;
      color: v.$color5;
      font-weight: bold;
      letter-spacing: -0.02em;
      @include r.Mulish;

      @include r.sp {
        font-size: 5rem;
      }
    }

    &jp {
      display: block;
      font-size: 2.6rem;
      line-height: 2;
      color: v.$color1;
      font-weight: 600;
      letter-spacing: 0.05em;
      position: relative;
      padding-left: 22px;
      margin-left: 40px;

      @include r.sp {
        font-size: 1.8rem;
        letter-spacing: 0;
        padding-left: 12px;
        margin: -1px 0 0 26px;
      }

      &::before {
        content: '';
        width: 7px;
        height: 7px;
        background: v.$color5;
        border-radius: 50%;
        position: absolute;
        top: 22px;
        left: 0;

        @include r.sp {
          width: 4px;
          height: 4px;
          top: 17px;
        }
      }
    }
  }

  //----------------------------------------------------------
  &__txt {
    font-size: 1.8rem;
    line-height: 1.8;
    color: v.$color1;
    letter-spacing: 0.05em;
    margin: 7px 0 0 6px;

    @include r.sp {
      font-size: 1.2rem;
      line-height: 1.75;
      margin: 6px 0 0 13px;
    }
  }
}

.c-mv3 {
  padding: 106px 0 0;

  @include r.sp {
    padding: 40px 0 6px;
  }

  &__inner {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    @include r.sp {
      max-width: 100%;
      padding: 0 20px;
    }
  }
}
