@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
block
------------------------------------------------------------*/
.c-block1 {
  display: flex;
  flex-wrap: wrap;

  @include r.sp {
    flex-direction: column;
  }

  .c-title01 {
    &__jp {
      margin-top: -1px;
      padding-left: 41px;

      @include r.sp {
        margin-top: 3px;
        padding-left: 26px;
      }
    }
  }

  &__left {
    width: 52.2%;

    @include r.sp {
      width: 100%;
    }
  }

  &__txt {
    font-size: 1.6rem;
    line-height: 32px;
    padding: 0 0 0 46px;
    margin-top: 25px;
    color: #292929;

    @include r.sp {
      padding: 0 2px;
      line-height: 29px;
      margin-top: 21px;
    }
  }

  &__right {
    flex: 1;
    margin-top: 46px;

    @include r.sp {
      margin-top: 30px;
    }
  }
  &__wraplist {
    margin-left: 46px;
    @include r.sp {
      margin-left: 15px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 280px;
    margin: auto auto 19px;

    @include r.sp {
      max-width: 300px;
      padding: 0px 0 0 17px;
      margin: auto auto 24px;
    }
  }

  &__item {
    margin-bottom: 20px;
    width: 50%;
    @include r.sp {
      margin-bottom: 10px;
    }
  }

  &__link {
    text-decoration: none;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 32px;
    color: #292929;
    position: relative;
    padding-left: 24px;
    display: inline-block;

    @include r.hover{
      color: v.$color5;

      &::before{
        background: url(p.$path + 'images/common/icon/icon_arrow_right2_blue.svg') no-repeat;
      }
    }

    &:before {
      content: '';
      background: url(p.$path + 'images/common/icon/icon_arrow_right2.svg')
        no-repeat;
      width: 9px;
      height: 9px;
      background-size: 9px 9px !important;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease-in-out;
    }
  }
}

/*------------------------------------------------------------
c-block2
------------------------------------------------------------*/
.c-block2 {
  position: relative;
  $root: &;

  &__inner1 {
    display: flex;
    align-items: flex-start;
    max-width: 93.5vw;
    margin-right: 0;
    margin-left: auto;
    position: relative;
    z-index: 1;
    @include r.mq(xlless) {
      max-width: 96.4vw;
    }
    @include r.sp {
      flex-direction: column;
      max-width: 95.6vw;
    }
    .c-title01 {
      margin: 32px 1.5vw 0 0;
      @include r.sp {
        margin: 0;
      }
    }
  }

  &__block1 {
    padding: 57px 85px 17px 87px;
    width: 69.5vw;
    margin-left: auto;
    position: relative;
    @include r.mq(xlless) {
      width: 64vw;
      padding: 20px;
    }

    @include r.sp {
      padding: 30px 35px 17px 32px;
      width: 100%;
      margin-top: 30px;
      margin-left: 3px;
    }

    &:after {
      box-shadow: 0px 4px 80px rgba($color: #000, $alpha: 0.1);
      transition-delay: 0.3s;
    }
  }

  &__list1 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -18px;
    @include r.mq(xlless) {
      margin: 0 -10px;
    }
  }

  &__item1 {
    padding: 0 18px;
    width: calc(100% / 4);
    margin-bottom: 25px;
    @include r.mq(xlless) {
      padding: 0 10px;
      margin-bottom: 15px;
      width: calc(100% / 2);
    }

    @include r.sp {
      width: 50%;
      margin-bottom: 12.5px;
      padding: 0 10px;
    }
  }

  &__link {
    display: block;
    overflow: hidden;

    @include r.hover {
      #{$root}__img1 {
        transform: scale(0.98);
      }
    }
  }

  &__img1 {
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after {
      content: '';
      display: block;
      padding-top: calc(80px / 180px * 100%);
      @include r.sp {
        padding-top: calc(60px / 135px * 100%);
      }
    }
  }

  &__txt1 {
    margin-top: 10px;
    display: inline-grid;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 24px;
    color: v.$color1;
    align-items: center;
    position: relative;

    @include r.mq(xlless) {
      font-size: 1.3rem;
    }

    @include r.sp {
      font-size: 1.1rem;
      margin-top: 6px;
    }

    &::after {
      content: '';
      background: url(p.$path + 'images/common/icon/icon_arrow_right.svg')
        no-repeat center;
      width: 9px;
      height: 12px;
      background-size: 9px 12px;
      margin-left: 10px;
      position: absolute;
      right: 2px;
    }
  }

  &__txt2 {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
  }

  .c-btn01 {
    margin-top: 31px;
    position: relative;
    z-index: 1;

    @include r.sp {
      margin-top: 29px;
    }
  }

  &__img2 {
    width: 95.5vw;
    position: absolute;
    z-index: 0;
    top: 235px;
    left: 0;
    bottom: 160px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include r.sp {
      width: 95vw;
      top: 284px;
      bottom: 166px;
    }
  }

  &__inner2 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: 89.3vw;
    position: relative;
    z-index: 1;
    padding: 72px 45px 91px 45px;
    margin-top: 77px;
    @include r.mq(xlless) {
      padding: 30px;
    }
    @include r.sp {
      margin-top: 53px;
      padding: 55px 20px 44px;
    }
    &:before {
      content: '';
      position: absolute;
      background: #fff;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 0.8s cubic-bezier(0.43, 0.05, 0.17, 1);
      top: 100%;
      z-index: -2;
    }
    &::after {
      background: url(p.$path + 'images/top/bg02.jpg') no-repeat center / cover;
      transition: all 0.8s cubic-bezier(0.43, 0.05, 0.17, 1);
      transition-delay: 0.5s;
      @include r.sp {
        background: url(p.$path + 'images/top/bg02-sp.jpg') no-repeat center /
          cover;
      }
    }

    .c-title01 {
      margin: 11px 0 0;
      @include r.sp {
        margin: 0;
      }
      &__jap {
        color: v.$color2;
      }
    }
  }

  &__title1 {
    display: block;
    font-size: 2rem;
    line-height: 40px;
    color: v.$color2;
    font-weight: bold;
    position: relative;
    padding-left: 22px;

    @include r.max-w(1240px) {
      font-size: 1.8rem;
    }

    @include r.sp {
      font-size: 1.8rem;
      padding-left: 21px;
      margin: 0px 0 0 -4px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 16px;
      left: 8px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: v.$color5;

      @include r.max-w(1240px) {
        top: 50%;
        transform: translateY(-50%);
      }

      @include r.sp {
        width: 4px;
        height: 4px;
      }
    }
  }

  &__block2 {
    width: 45.4%;
    max-width: 542px;
    margin-top: 11px;
    margin-left: 125px;
    @include r.mq(xlless) {
      margin-left: 35px;
      width: 45%;
    }

    @include r.sp {
      width: 100%;
      margin-top: 55px;
      margin-left: 0;
    }
  }

  &__list2 {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 52px;
    gap: 0 23px;
    @include r.mq(xlless) {
      margin-top: 32px;
      gap: 0 15px;
    }

    @include r.sp {
      margin-top: 16px;
      gap: 0 13px;
    }
  }

  &__item2 {
    width: calc((100% - 92px) / 5);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 27px;
    @include r.mq(xlless) {
      margin-bottom: 15px;
      width: calc((100% - 60px) / 5);
    }
    @include r.sp {
      width: calc((100% - 52px) / 5);
    }
  }

  &__link1 {
    border-radius: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 2.3rem;
    line-height: 23px;
    color: v.$color1;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      padding-top: 100%;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      z-index: -1;
      background: #fff;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      transition: all 0.3s ease-out;
      border-radius: 50%;
      z-index: 0;
      background: v.$color5;
    }

    @include r.hover {
      color: #fff;

      &::before {
        width: 110%;
        height: 110%;
      }
    }
    @include r.sp {
      font-size: 1.25rem;
      width: 48px;
    }
    span {
      position: absolute;
    }
  }
}

/*
------------------------------------------------------------*/
.c-block3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  $root: &;

  //-------------------------------------------------------
  &__map {
    width: 61.15%;
    @include r.max-w(1240px) {
      width: 55%;
    }
    @include r.sp {
      width: 100%;
    }

    &__inner {
      width: 100%;
      height: 100%;
      position: relative;
      @include r.sp {
        width: calc(100% + 40px);
        margin: 0 -20px;
        height: 100vw;
      }
    }

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }

    &__btn {
      width: 110px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        v.$color5;
      border-radius: 5px;
      text-align: center;
      font-size: 1.2rem;
      line-height: 2;
      color: v.$color2;
      font-weight: 600;
      margin: 11px 0 0;
      cursor: pointer;
      transition: all 0.3s ease-out;
      @include r.sp {
        margin: 10px 0 0;
      }

      span {
        display: inline-block;
        position: relative;
        padding-left: 15px;

        &::before {
          content: '';
          width: 13px;
          height: 13px;
          background: url(p.$path + 'images/common/icon/icon_reload.svg')
            no-repeat center / cover;
          position: absolute;
          top: 50%;
          left: -3px;
          transform: translate(0, -50%);
        }
      }

      @include r.hover {
        background: v.$color5;
        transform: scale(0.9);
      }
    }
  }

  //----------------------------------------------------------
  &__info {
    width: 37.3%;
    @include r.max-w(1240px) {
      width: 42%;
    }
    @include r.sp {
      width: 100%;
      margin: 20px 0 0;
    }
  }

  //----------------------------------------------------------
  &__box {
    background: v.$color9;
    padding: 33px 32px 41px;
    @include r.max-w(1024px) {
      padding: calc((33 / 1024) * 100vw) calc((32 / 1024) * 100vw)
        calc((41 / 1024) * 100vw);
    }
    @include r.sp {
      padding: 20px 14px 25px;
    }

    &:not(:last-child) {
      padding: 24px 32px;
      margin: 0 0 15px;
      @include r.max-w(1024px) {
        padding: calc((24 / 1024) * 100vw) calc((32 / 1024) * 100vw);
      }
      @include r.sp {
        padding: 16px 14px;
        margin: 0 0 8px;
      }
    }
  }

  //----------------------------------------------------------
  &__wrap {
    &:not(:last-child) {
      margin: 0 0 37px;
      @include r.sp {
        margin: 0 0 15px;
      }
    }

    #{$root}__txt {
      line-height: 1.6;
      @include r.sp {
        line-height: 1.5;
      }
    }
  }

  //----------------------------------------------------------
  &__note {
    font-size: 1.2rem;
    line-height: 1.8;
    color: v.$color1;
    font-weight: 600;
    letter-spacing: 0.05em;
    position: relative;
    padding-left: 26px;
    margin: 0 0 12px;
    @include r.max-w(1024px) {
      font-size: calc((12 / 1024) * 100vw);
      padding-left: calc((26 / 1024) * 100vw);
    }
    @include r.sp {
      font-size: 1.2rem;
      padding-left: 24px;
      letter-spacing: 0;
      margin: 0 0 6px;
    }

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background: url(p.$path + 'images/common/icon/icon_location_black.svg')
        no-repeat center / cover;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      @include r.max-w(1024px) {
        width: calc((24 / 1024) * 100vw);
        height: calc((24 / 1024) * 100vw);
      }
      @include r.sp {
        width: 24px;
        height: 24px;
        left: -2px;
      }
    }
  }

  //----------------------------------------------------------
  &__ttl {
    font-size: 2.6rem;
    line-height: 1.5;
    color: v.$color1;
    font-weight: 600;
    letter-spacing: 0.08em;
    margin: 0 0 2px;
    @include r.max-w(1024px) {
      font-size: calc((26 / 1024) * 100vw);
    }
    @include r.sp {
      font-size: 1.8rem;
      margin: 0;
    }
  }

  //----------------------------------------------------------
  &__ttl2 {
    font-size: 2rem;
    line-height: 1.6;
    color: v.$color1;
    font-weight: 600;
    letter-spacing: 0.08em;
    margin: 0 0 1px;
    @include r.max-w(1024px) {
      font-size: calc((20 / 1024) * 100vw);
    }
    @include r.sp {
      font-size: 1.8rem;
    }
  }

  //----------------------------------------------------------
  &__txt {
    font-size: 1.6rem;
    line-height: 1.5;
    color: v.$color1;
    font-weight: 500;
    @include r.max-w(1024px) {
      font-size: calc((16 / 1024) * 100vw);
    }
    @include r.sp {
      font-size: 1.4rem;
    }
    @include r.max-w(374px) {
      font-size: 1.2rem;
    }

    span {
      color: v.$color5;
      font-weight: bold;
    }
    .u-phone {
      pointer-events: none;
      @include r.sp {
        pointer-events: auto;
      }
    }
    .fax {
      pointer-events: none;
    }
  }

  //-------------------------------------------------------
  &__form {
    max-width: 80%;
    width: 280px;
    margin: 7px auto 0;
    @include r.sp {
      max-width: 100%;
      margin: 13px auto 0;
    }

    input {
      display: block;
      width: 100%;
      background: url(p.$path + 'images/common/icon/icon_search.svg') no-repeat
          center left 20px / 20px,
        v.$color2;
      box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.1);
      border-radius: 120px;
      box-sizing: border-box;
      font-size: 1.4rem;
      line-height: 1.6;
      color: v.$color1;
      font-weight: 700;
      font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic',
        'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
        sans-serif !important;
      padding: 15px 20px 13px 50px;
      outline: none;
      @include r.max-w(1024px) {
        background-position: center left calc((20 / 1024) * 100vw);
        background-size: calc((20 / 1024) * 100vw);
        font-size: calc((14 / 1024) * 100vw);
        padding: calc((15 / 1024) * 100vw) calc((20 / 1024) * 100vw)
          calc((13 / 1024) * 100vw) calc((50 / 1024) * 100vw);
      }
      @include r.sp {
        background-position: center left 20px;
        background-size: 20px;
        font-size: 1.4rem;
        padding: 15px 20px 13px 50px;
      }

      &::placeholder {
        color: v.$color1;
      }
    }
  }

  .c-btn03 {
    margin-top: 9px;
    max-width: 80%;
    @include r.sp {
      margin-top: 13px;
      max-width: 100%;
    }

    &__icon {
      @include r.max-w(1024px) {
        width: calc((24 / 1024) * 100vw);
        height: calc((24 / 1024) * 100vw);
      }
      @include r.sp {
        width: 24px;
        height: 24px;
      }
    }

    &__txt {
      @include r.max-w(1024px) {
        font-size: calc((14 / 1024) * 100vw);
      }
      @include r.sp {
        font-size: 1.4rem;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-block4 {
  //-------------------------------------------------------
  &__ttl {
    font-size: 2.6rem;
    line-height: 1.5;
    color: v.$color1;
    font-weight: 600;
    letter-spacing: 0.08em;
    margin: 0 0 0 2px;
    @include r.pad {
      font-size: calc((26 / 1024) * 100vw);
    }
    @include r.sp {
      font-size: 2rem;
      line-height: 1.3;
      margin: 0;
    }

    span {
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0;
      margin: 0 0 0 10px;
      @include r.pad {
        font-size: calc((12 / 1024) * 100vw);
      }
      @include r.sp {
        display: block;
        font-size: 1.1rem;
        margin: 0;
      }
    }
  }

  //-------------------------------------------------------
  &__map {
    width: 100%;
    height: 400px;
    margin: 13px 0 0;
    @include r.sp {
      height: 78.7vw;
      margin: 10px 0 0;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  //-------------------------------------------------------
  &__info {
    margin: 15px 0 0;
    @include r.sp {
      margin: 13px 0 0;
    }
  }

  //-------------------------------------------------------
  &__txt {
    font-size: 1.6rem;
    line-height: 1.5;
    color: v.$color1;
    font-weight: 500;
    @include r.pad {
      font-size: calc((16 / 1024) * 100vw);
    }
    @include r.sp {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.4rem;
    }
    @include r.max-w(374px) {
      font-size: calc((14 / 375) * 100vw);
    }

    span {
      font-weight: bold;
      color: v.$color5;
    }

    .fax {
      pointer-events: none;
    }
  }
}

/*------------------------------------------------------------
c-block5
------------------------------------------------------------*/
.c-block5 {
  $root: &;
  position: relative;
 
  &:before {
    content: "";
    position: absolute;
    background: url(p.$path + 'images/company/overview/bg01.png') no-repeat;
    background-size: 100% 100%;
    background-position: left top;
    height: 100%;
    width: 100%;
    right: 0;
    left: 0;
    top: -50px;
    z-index: -1;

    @include r.sp{
      top: -20px;
    }
  }
 
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 78px 0 75px 21px;
    max-width: 1282px;
    margin: 0 auto;
    @include r.mq(xlless) {
      padding: 70px 30px 90px 50px;
    }

    @include r.sp {
      padding: 56px 20px 30px 10px;
    }
  }

  &__detail {
    margin-top: 129px;
    flex: 1;

    @include r.mq(xlless) {
      margin-top: 40px;
    }

    @include r.sp {
      margin-top: 0;
    }
  }

  &__title1 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @include r.sp {
      padding-left: 10px;
    }

    &::before {
      content: '';
      width: 6px;
      height: 6px;
      background: v.$color6;
      border-radius: 50%;
      margin-top: 10px;
      display: inline-block;
    }
  }

  &__txt2 {
    margin-left: 18px;
    flex: 1;
    font-weight: 600;
    font-size: 2.6rem;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: v.$color1;

    @include r.mq(xlless) {
      font-size: 2.4rem;
      margin-left: 10px;
    }

    @include r.sp {
      font-size: 1.8rem;
      margin-left: 8px;
    }
  }

  &__list1 {
    margin-top: 57px;

    @include r.mq(xlless) {
      margin-top: 37px;
    }

    @include r.sp {
      margin-top: 14px;
    }
  }

  &__item1 {
    margin-bottom: 41px;

    @include r.mq(xlless) {
      margin-bottom: 31px;
    }

    @include r.sp {
      margin-bottom: 17px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title2 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: v.$color5;
    padding-left: 2px;

    @include r.mq(xlless) {
      font-size: 1.8rem;
    }

    @include r.sp {
      font-size: 1.8rem;
      padding-left: 0px;
    }
  }

  &__txt1 {
    font-weight: 400;
    margin-top: 7px;
    padding-left: 10px;
    font-size: 1.6rem;
    line-height: 24px;
    color: v.$color1;

    @include r.mq(xlless) {
      font-size: 1.4rem;
    }

    @include r.sp {
      font-size: 1.6rem;
      margin-top: 5px;
    }
  }

  &__list2 {
    display: flex;
    flex-direction: column;
    margin-left: 13px;
    margin-top: 45px;
    width: 44.8%;

    @include r.mq(xlless) {
      margin-left: 15px;
      width: 45%;
    }
  }

  &__item2 {
    margin-bottom: 25px;
    &:first-child {
      #{$root} {
        &__img1 {
          margin-left: 58px;
          width: 66%;
        }
      }
    }
    &:nth-child(2) {
      #{$root} {
        &__img1 {
          width: 55%;
          margin-left: auto;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
      #{$root} {
        &__img1 {
          width: 72.7%;
        }
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-block6 {
  .c-title02 {
    margin-bottom: 3px;
    @include r.sp {
      margin-bottom: 1px;
    }
  }
  &__innertxt {
    max-width: 1380px;
    padding: 0 20px;
    margin: 0 auto;

    @include r.sp{
      padding: 0 20px 20px;
    }
  }
  &__slidelist {
    max-width: 1380px;
    padding: 0 20px;
    margin: 50px auto 0;
    @include r.sp {
      padding: 0 60px;
      margin-top: 4px;
      position: relative;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 16px;
    @include r.sp {
      flex-wrap: initial;
      gap: 0;
    }
  }
  &__img {
    position: relative !important;
    overflow: hidden;
    width: calc((100% - 32px) / 3) !important;
    @include r.sp {
      width: 100% !important;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
    &:after {
      content: '';
      display: block;
      padding-top: calc(290px / 436px * 100%);
      @include r.sp {
        padding-top: calc(170px / 255px * 100%);
      }
    }
  }
  &__navi {
    display: none;
    @include r.sp {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 70px);
      top: calc(50% - 19px);
      position: absolute;
      left: 36px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 38px;
      height: 38px;
      position: initial;
      margin: 0;
      overflow: hidden;
      outline: none;
      font-size: 0;
      box-shadow: 0px 2.85714px 35.7143px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      &:after {
        content: none;
      }
    }
  }
  &.is-style1 {
    .c-title02 {
      margin-bottom: 9px;
      @include r.sp {
        margin-bottom: 3px;
      }
    }
    .c-block6 {
      &__slidelist {
        margin-top: 52px;
        @include r.sp {
          margin-top: 32px;
        }
      }
    }
  }
}

.c-block7 {
  &__text1 {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 36px;
    letter-spacing: 0.12em;
    color: #292929;
    margin-top: 4px;
    @include r.sp {
      font-size: 1.6rem;
      line-height: 29px;
      letter-spacing: 0.05em;
      margin-top: 6px;
    }
  }
  &__list {
    display: flex;
    gap: 0 49px;
    padding: 27px 39px 60px;
    background: #f2f4f5;
    margin: 42px 0 0;
    @include r.sp {
      flex-direction: column;
      gap: 49px 0;
      padding: 32px 20px 53px;
      margin: 38px 0 0;
    }
  }
  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-height: 117px;
    position: relative;
    @include r.sp {
      position: initial;
      min-height: auto;
    }
    &.u-img1 {
      img {
        max-width: 110px;
      }
    }
    &.u-img2 {
      img {
        max-width: 134px;
      }
    }
    &.u-img3 {
      img {
        max-width: 127px;
      }
    }
    &.u-img4 {
      img {
        max-width: 127px;
      }
      &:after {
        content: none;
      }
    }
    &:after {
      content: '';
      position: absolute;
      background: url(p.$path + 'images/common/icon/icon_arrow_step.svg')
        center/cover no-repeat;
      width: 29px;
      height: 12px;
      top: calc(50% + 5px);
      left: calc(100% + 7px);
      @include r.sp {
        top: calc(100% + 19px);
        left: calc(50% - 15px);
        transform: rotate(90deg);
      }
    }
  }
  &__ttl {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 36px;
    color: #0066ff;
    margin-top: 8px;
    @include r.sp {
      margin-top: 3px;
      margin-bottom: -2px;
    }
  }
  &__text2 {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 24px;
    color: #292929;
    .u-blue1 {
      font-weight: 600;
      color: #0066ff;
    }
  }
  &__item {
    width: calc(100% / 4 - 39px);
    max-width: 270px;
    position: relative;
    @include r.sp {
      width: 100%;
      max-width: 100%;
    }
  }
  .c-title02 {
    @include r.sp {
      letter-spacing: 0.1em;
    }
  }
}

.c-block8 {
  max-width: 1045px;
  margin: 0 auto;
  padding: 53px 0 0 7px;
  position: relative;
  z-index: 0;
  @include r.sp {
    padding: 35px 0 0;
  }
  &__ttl {
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 44px;
    letter-spacing: 0.12em;
    color: #292929;
    margin: 9px 0 12px;
    @include r.sp {
      font-size: 1.8rem;
      line-height: 27px;
      letter-spacing: 0;
      margin: 0 0 22px;
    }
  }
  &__text1 {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 29px;
    color: #292929;
  }
  &__text2 {
    margin-top: 18px;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 29px;
    color: #292929;
    @include r.sp {
      margin-top: 11px;
    }
  }
  &__text3 {
    font-size: 1.4rem;
    line-height: 31px;
    color: #292929;
    @include r.sp {
      line-height: 25px;
      margin-top: 8px;
    }
  }
  &__img {
    max-width: 874px;
    margin-left: auto;
    margin-top: -358px;
    text-align: right;
    position: relative;
    z-index: -1;
    @include r.max-w(1024px) {
      margin-top: -22.6%;
      margin-left: 25%;
    }
    @include r.sp {
      margin-top: 33px;
      margin-left: auto;
    }
    img {
      width: 100%;
      max-width: 874px;
      position: absolute;
      object-fit: cover;
      right: 0;
      top: 0;
      @include r.sp {
        width: 100%;
      }
    }
    &:after {
      content: '';
      display: block;
      padding-top: calc(934px / 874px * 100%);
    }
  }
  .c-title02 {
    letter-spacing: 0.1em;
  }
}
