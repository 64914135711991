@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
navi
------------------------------------------------------------*/
.c-navi1 {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  $r: &;

  @include r.sp {
    flex-direction: column;
    gap: 30px;
    padding: 0 24px;
  }

  &__item {
    width: calc((100% - 40px) / 3);
    padding: 30px 24px 32px 25px;
    border: 1px dashed #373737;
    border-radius: 40px;

    @include r.sp {
      width: 100%;
      border-radius: 20px;
      padding: 28px 26px 23px;
    }

    &:nth-child(1) {
      #{$r}__row {
        padding: 0 8px;

        @include r.sp {
          padding: 0;
          margin-bottom: 9px;
        }
      }

      #{$r}__head {
        padding-left: 27px;

        @include r.sp {
          padding-left: 11px;
        }
      }

      #{$r}__icon {
        margin-top: 3px;

        @include r.sp {
          margin-top: 2px;
          padding-left: 5px;
        }
      }
    }

    &:nth-child(2) {
      #{$r}__icon {
        text-align: left;
      }

      #{$r}__head {
        padding-left: 23px;

        @include r.sp {
          padding-left: 11px;
        }
      }
    }

    &:nth-child(3) {
      #{$r}__icon {
        margin-top: 5px;
      }

      #{$r}__row {
        padding: 0 7px;
      }

      #{$r}__head {
        padding-left: 18px;

        @include r.sp {
          padding-left: 11px;
        }
      }
    }
  }

  &__row {
    display: flex;
    margin-bottom: 23px;

    @include r.sp {
      margin-bottom: 16px;
    }
  }

  &__icon {
    width: 70px;
    text-align: center;

    @include r.sp {
      text-align: left;
    }

    img {
      width: auto;
    }
  }

  &__head {
    width: calc(100% - 68px);

    @include r.sp {
      padding-left: 11px;
    }
  }

  &__ttl1 {
    font-family: 'Koburina Gothic Std ';
    font-weight: 600;
    font-size: 2rem;
    line-height: 36px;
    letter-spacing: 0.1em;
    color: #373737;

    @include r.sp {
      font-size: 1.6rem;
      line-height: 29px;
    }
  }

  &__ttl2 {
    font-family: 'Fenwick';
    font-size: 3rem;
    line-height: 31px;
    letter-spacing: -0.11em;
    color: #959595;
    opacity: 0.3;

    @include r.sp {
      font-size: 2.5rem;
      line-height: 30px;
    }
  }

  &__txt {
    font-family: 'Koburina Gothic Std ';
    font-size: 1.6rem;
    line-height: 29px;
    letter-spacing: 0.03em;
    color: #373737;

    @include r.sp {
      font-size: 1.4rem;
      line-height: 22px;
    }
  }
}

/*
------------------------------------------------------------*/
.c-navi2 {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  $root: &;
  @include r.sp {
    flex-direction: column;
    gap: 21px;
  }

  &__item {
    width: calc((100% - 16px) / 2);
    color: #fff;
    box-sizing: border-box;
    position: relative;

    @include r.sp {
      width: 100%;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;

    @include r.hover {
      transform: scale(0.98);

      #{$root}__img {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    @include r.sp {
      border-radius: 5px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease-out;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(0, 102, 255, 0.5),
        rgba(0, 102, 255, 0.5)
      );
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__info {
    width: 100%;
    height: 100%;
    min-height: 420px;
    position: relative;
    z-index: 2;
    padding: 52px 37px 45px;
    @include r.max-w(1090px) {
      padding: 52px 20px 45px;
    }
    @include r.sp {
      min-height: 232px;
      padding: 17px 22px 40px;
    }
  }

  &__main {
    display: block;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 6.5rem;
    line-height: 82px;
    letter-spacing: -0.02em;
    @include r.max-w(1090px) {
      font-size: 4.5rem;
    }
    @include r.sp {
      font-size: 3.8rem;
      line-height: 42px;
    }
  }

  &__sub {
    display: block;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 43px;
    padding: 0 7px;

    @include r.sp {
      font-size: 2rem;
      line-height: 43px;
      padding: 0;
    }
  }

  &__des {
    font-size: 1.6rem;
    line-height: 29px;
    font-weight: 600;
    letter-spacing: 0.05em;
    padding: 15px 0 0 9px;

    @include r.sp {
      font-size: 1.4rem;
      line-height: 25px;
      padding: 5px 0 0 0;
    }
  }

  &__btn {
    position: absolute;
    bottom: 14px;
    right: 27px;

    @include r.sp {
      bottom: 11px;
      right: 22px;
    }

    &__txt {
      margin-right: auto;
      margin-top: auto;
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 28px;

      &:after {
        content: '';
        background: url(p.$path + 'images/common/icon/icon_arrow_right_white.svg')
          no-repeat;
        width: 9px;
        height: 12px;
        display: inline-block;
        margin-left: 11px;
        background-size: 9px 12px;
        margin-bottom: -1px;
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-navi3 {
  max-width: 1240px;
  margin: 0 auto;
  position: relative;
  $root: &;

  //-------------------------------------------------------
  &__heading {
    width: 100%;
    pointer-events: none;
    @include r.sp {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        v.$color5;
      padding: 13px 26px;
      position: relative;
      pointer-events: all;
    }

    &:after {
      @include r.sp {
        content: '';
        width: 11px;
        height: 7px;
        background: url(p.$path + 'images/common/icon/icon_arrow_up.svg')
          no-repeat center / cover;
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translate(0, -50%);
        transition: all 0.3s ease-out;
      }
    }

    &.is-open {
      &::after {
        transform: translate(0, -50%) rotate(180deg);
      }
    }
  }

  //-------------------------------------------------------
  &__ttl {
    font-size: 1.4rem;
    line-height: 2;
    color: v.$color1;
    font-weight: 600;
    letter-spacing: 0.08em;
    position: relative;
    padding-left: 13px;

    @include r.sp {
      font-size: 1.2rem;
      color: v.$color2;
      letter-spacing: 0.05em;
    }

    &::before {
      content: '';
      width: 7px;
      height: 7px;
      border: 1.5px solid v.$color6;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);

      @include r.sp {
        border-color: v.$color2;
      }
    }

    span {
      border-left: 2px solid v.$color2;
      padding-left: 10px;
      margin-left: 13px;
    }
  }

  //-------------------------------------------------------
  &__info {
    margin: 12px 0 0;

    @include r.sp {
      margin: 0;
      background: v.$color5;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: auto;
      left: 0;
      z-index: 99;
      transition: all 0.3s ease-out;
    }
  }

  //-------------------------------------------------------
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  //-------------------------------------------------------
  &__item {
    margin: 0 12px 11px 0;

    @include r.sp {
      width: 100%;
      margin: 0;
      border-top: 1px solid v.$color25;
    }

    &:last-child {
      @include r.sp {
        border-bottom: 1px solid v.$color25;
      }
    }

    &.is-active {
      #{$root}__txt {
        background: v.$color5;
      }
    }
  }

  //-------------------------------------------------------
  &__txt {
    display: block;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      v.$color5;
    border-radius: 40px;
    font-size: 1.6rem;
    line-height: 2;
    color: v.$color2;
    font-weight: 600;
    padding: 0 15px;
    transition: all 0.3s ease-out;
    cursor: pointer;

    @include r.sp {
      border-radius: 0;
      font-size: 1.4rem;
      padding: 5.5px 40px;
    }

    @include r.hover {
      transform: scale(0.9);
      background: v.$color5;
    }
  }

  &.is-active {
    .c-navi3 {
      &__heading {
        &::after {
          transform: translate(0, -50%) rotate(180deg);
        }
      }

      &__info {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.c-navi4 {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    flex: 1;
    position: relative;
    padding: 0 2px;
    cursor: pointer;
    @include r.sp {
      flex: calc(100% / 3);
      max-width: 33.3333333333%;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: calc(100% - 17px);
      background: #a6a6a6;
      right: 0;
      @include r.sp {
        height: calc(100% - 19px);
      }
    }

    &:before {
      right: auto;
      left: -1px;
    }

    &:first-of-type {
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: calc(100% - 17px);
        background: #a6a6a6;
        left: 0;
        right: auto;
        @include r.sp {
          height: calc(100% - 19px);
        }
      }
    }
  }

  &__link {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: 0.08em;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    @include r.sp {
      padding: 1px 0;
      line-height: 33px;
    }

    &.is-active {
      color: #0066ff;
    }

    @include r.hover {
      color: #0066ff;
    }
  }

  .c-title04 {
    margin-bottom: 16px;
    @include r.sp {
      margin-bottom: 9px;
    }
  }
}

.wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  margin: 0 auto;
  position: relative;

  .extend {
    display: none;
  }

  .page,
  .current {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: bold;
    @include r.YuGothic;
    color: #000;
    background: #fff;
    margin: 0 7.5px;
    border: none;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);

    @include r.hover {
      background: v.$color5;
      color: #fff;
    }

    @include r.sp {
      font-size: 1.4rem;
      flex-shrink: 0;
    }
  }

  .current {
    background: v.$color5;
    color: #fff;
  }

  .previouspostslink,
  .nextpostslink {
    width: 72px;
    height: 72px;
    position: absolute;
    top: -17px;
    border-radius: 50%;
    font-size: 1.6rem;
    font-weight: bold;
    color: v.$color1;
    @include r.Mulish;
    text-align: center;
    padding: 19px 0;
    border: none;

    @include r.hover {
      background: v.$color5;
      color: #fff;
    }

    @include r.sp {
      font-size: 1.2rem;
      width: 40px;
      height: 40px;
      padding: 9px 0;
      top: -2px;
    }

    &::before {
      content: '';
      display: block;
      width: 7.2px;
      height: 12px;
      background: url('../images/common/icon/icon_arrow_right.svg') no-repeat
        center;
      background-size: 7.2px 12px;
      margin: 0 auto 4px;

      @include r.sp {
        width: 9px;
        height: 12px;
        background-size: 9px 12px;
        margin: 0 auto 2px;
      }
    }
  }

  .nextpostslink {
    right: -167px;

    @include r.sp {
      right: -79px;
    }

    @include r.max-w(374px) {
      margin-left: 12.5px;
    }
  }

  .previouspostslink {
    left: -167px;

    @include r.sp {
      left: -79px;
    }

    @include r.max-w(374px) {
      margin-right: 12.5px;
    }

    &::before {
      transform: rotate(180deg);
    }
  }
}

.c-navi5 {
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    a[rel~='next'],
    a[rel~='prev'] {
      display: block;
      width: 72px;
      height: 72px;
      position: relative;
      border-radius: 50%;
      font-size: 1.6rem;
      font-weight: bold;
      color: v.$color1;
      @include r.Mulish;
      text-align: center;
      padding: 19px 0;
      flex-shrink: 0;

      @include r.hover {
        background: v.$color5;
        color: #fff;
      }

      @include r.sp {
        font-size: 1.2rem;
        width: 40px;
        height: 40px;
        padding: 9px 0;
      }

      &::before {
        content: '';
        display: block;
        width: 9px;
        height: 12px;
        background: url('../images/common/icon/icon_arrow_right.svg') no-repeat
          center;
        background-size: 9px 12px;
        margin: 0 auto 4px;
        position: relative;

        @include r.sp {
          width: 9px;
          height: 12px;
          background-size: 9px 12px;
          margin: 0 auto 3px;
          top: -1px;
        }
      }
    }

    a[rel~='next'] {
    }

    a[rel~='prev'] {
      &::before {
        transform: rotate(180deg);
        @include r.sp {
          left: -2px;
        }
      }
    }
  }

  &__link {
    &--prev,
    &--next {
      display: block;
      width: 72px;
      height: 72px;
      position: relative;
      border-radius: 50%;
      font-size: 1.6rem;
      font-weight: bold;
      color: v.$color1;
      @include r.Mulish;
      text-align: center;
      padding: 19px 0;
      flex-shrink: 0;

      @include r.hover {
        background: v.$color5;
        color: #fff;
      }

      @include r.sp {
        font-size: 1.2rem;
        width: 40px;
        height: 40px;
        padding: 9px 0;
      }

      &::before {
        content: '';
        display: block;
        width: 9px;
        height: 12px;
        background: url('../images/common/icon/icon_arrow_right.svg') no-repeat
          center;
        background-size: 9px 12px;
        margin: 0 auto 4px;
        position: relative;

        @include r.sp {
          width: 9px;
          height: 12px;
          background-size: 9px 12px;
          margin: 0 auto 3px;
          top: -1px;
        }
      }
    }

    &--next {
    }

    &--prev {
      &::before {
        transform: rotate(180deg);
        @include r.sp {
          left: -2px;
        }
      }
    }
  }

  .c-btn01 {
    width: 280px;
    margin: 0 64px;

    @include r.sp {
      width: 200px;
      margin: 0 33px;
    }

    @include r.max-w(374px) {
      width: 160px;
      margin: 0 20px;
    }

    &__link {
      @include r.max-w(374px) {
        padding: 15px;

        &::after {
          right: 14px;
        }
      }
    }
  }
}

.c-navi6 {
  display: flex;
  justify-content: flex-end;

  @include r.sp {
    flex-direction: column;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 31px;

      @include r.sp {
        margin-right: 0;
        margin-bottom: 6px;
      }
    }
  }

  &__link {
    @include r.YuGothic;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.05em;
    color: #292929;
    display: inline-flex;
    align-items: center;

    @include r.sp {
      font-weight: 600;
      font-size: 1.4rem;
    }

    @include r.hover{
      &::after{
        transform: scale(0.8);
      }
    }

    &:after {
      content: '';
      display: inline-block;
      width: 42px;
      height: 42px;
      background: url(p.$path + 'images/common/icon/icon_arrow_down_green.svg')
        center/cover no-repeat;
      border-radius: 50%;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
      margin-left: 14px;
      transition: all 0.3s ease-in-out;

      @include r.sp {
        width: 24px;
        height: 24px;
        margin-left: 9px;
      }
    }
  }
}

/*------------------------------------------------------------
c-navi8
------------------------------------------------------------*/
.c-navi8 {
  display: flex;
  align-items: flex-start;

  &__item {
    margin-right: 14px;

    @include r.sp {
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    display: inline-block;
    background: v.$color5;
    border-radius: 40px;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 24px;
    text-align: center;
    color: v.$color2;
    padding: 4px 15px;
    cursor: pointer;

    @include r.sp {
      font-size: 1.1rem;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.08em;
      padding: 2px 13px;
    }

    @include r.max-w(374px) {
      padding: 2px 7px;
      font-size: 1rem;
    }
  }

  &__content{

  }

  &__tabcontent{
    display: none;

    &.is-active{
      display: block;
    }
  }
}
