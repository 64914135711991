@use '../settings/mixin' as r;
@use '../settings/variable' as v;
@use '../settings/_path' as p;

[data-aos='animationText'] {
  $root: &;

  * {
    // overflow: hidden;

    span {
      display: inline-block;
      position: relative;
      transform: translate(160px, 50px);
      -webkit-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
      transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
      color: v.$color18;
      will-change: transform;
      opacity: 0;
    }
  }

  &.aos-animate {
    * {
      span {
        opacity: 1;
        transform: translate(0, 0);
        color: v.$color5;
      }
    }
  }
}

[data-aos='animationText1'] {
  $root: &;

  * {
    overflow: hidden;

    span {
      display: inline-block;
      position: relative;
      transform: translate(160px, 50px);
      -webkit-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
      transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
      color: v.$color28;
      will-change: transform;
      opacity: 0;
    }
  }

  &.aos-animate {
    * {
      span {
        opacity: 1;
        transform: translate(0, 0);
        color: v.$color1;
      }
    }
  }
}

[data-aos='scroll-up'] {
  position: relative;

  &::after {
    content: '';
    background: v.$color2;
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    transition: all 0.8s cubic-bezier(0.43, 0.05, 0.17, 1);
  }

  &.aos-animate {
    &::after {
      top: 0%;
    }
    &::before {
      top: 0%;
    }
  }
}

[data-fadein='js-scroll-fade-in'] {
  position: relative;
  top: 50px;
  opacity: 0;
  transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transition;
  &.in-fadein {
    top: 0;
    opacity: 1;
  }
}
