@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
btn
------------------------------------------------------------*/
.c-btn01 {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  background: v.$color2;
  box-shadow: 0px 4px 80px 0px rgba($color: #000000, $alpha: 0.1);
  border-radius: 120px;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  $root: &;

  &__link {
    padding: 15px 30px;
    @include r.YuGothic;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 20px;
    color: v.$color1;
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    z-index: 2;

    &::after {
      content: '';
      background: url(p.$path + 'images/common/icon/icon_arrow_right.svg')
        no-repeat center;
      position: absolute;
      width: 9px;
      height: 12px;
      background-size: 9px 12px;
      right: 28px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    transition: all 0.5s ease-out;
  }

  &::after {
    background: #00359d;
  }

  &::before {
    background: #0066ff;
    transition-delay: 0.1s;
    z-index: 1;
  }

  @include r.hover {
    &::after {
      width: 400px;
      height: 400px;
      transition-delay: 0.1s;
    }

    &::before {
      width: 280px;
      height: 280px;
    }

    .c-btn01__link {
      color: #fff;
    }
  }

  &.is-style1 {
    #{$root}__link {
      &::after {
        width: 12px;
        height: 9px;
        background: url(p.$path + 'images/common/icon/icon_arrow_up_green.svg')
          no-repeat center;
      }
    }
  }

  &.is-back{
    #{$root}__link{
      &::after{
        display: none;
      }

      &::before {
        content: '';
        background: url(p.$path + 'images/common/icon/icon_arrow_right.svg')
          no-repeat center;
        position: absolute;
        width: 9px;
        height: 12px;
        background-size: 9px 12px;
        left: 31px;
        top: 50%;
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-btn02 {
  max-width: 100%;
  width: 188px;
  $root: &;

  //----------------------------------------------------------
  &__link {
    width: 100%;
    background: v.$color2;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 0 4px;

    @include r.hover {
      background: v.$color5;

      #{$root}__icon {
        .on {
          opacity: 1;
        }

        .off {
          opacity: 0;
        }
      }

      #{$root}__txt {
        color: v.$color2;
      }
    }
  }

  //----------------------------------------------------------
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    text-align: center;
    margin: -1px 3px 0 0;
    position: relative;

    img {
      width: auto;
      vertical-align: top;
      transition: all 0.3s ease-out;
    }

    .on {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }

  //----------------------------------------------------------
  &__txt {
    display: block;
    font-size: 1.4rem;
    line-height: 1.5;
    color: v.$color1;
    font-weight: 600;
    transition: all 0.3s ease-out;
  }
}

/*
------------------------------------------------------------*/
.c-btn03 {
  max-width: 100%;
  width: 280px;
  margin: 0 auto;
  $root: &;

  //----------------------------------------------------------
  &__link {
    width: 100%;
    background: v.$color2;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.1);
    border-radius: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 0 12px;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      width: 0;
      height: 0;
      background: v.$color16;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.5s ease-out;
    }

    @include r.hover {
      &::after {
        width: 280px;
        height: 280px;
      }

      #{$root}__icon {
        .on {
          opacity: 1;
        }

        .off {
          opacity: 0;
        }
      }

      #{$root}__txt {
        color: v.$color2;
      }
    }
  }

  //----------------------------------------------------------
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    text-align: center;
    margin: -2px 4px 0 0;
    position: relative;
    z-index: 2;

    img {
      width: auto;
      vertical-align: top;
      transition: all 0.3s ease-out;
    }

    .on {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }

  //----------------------------------------------------------
  &__txt {
    display: block;
    font-size: 1.4rem;
    line-height: 1.5;
    color: v.$color1;
    font-weight: 600;
    transition: all 0.3s ease-out;
    position: relative;
    z-index: 2;
    @include r.YuGothic;
  }

  //----------------------------------------------------------
  &.is-color1 {
    #{$root}__link {
      &::after {
        background: v.$color5;
      }
    }
  }
}
