@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
form
------------------------------------------------------------*/

.c-form1 {
  display: flex;
  align-items: flex-start;

  .tk-dnp-shuei-gothic-gin-std {
    font-family: 'dnp-shuei-gothic-gin-std', sans-serif !important;
  }

  &.is-confirm {
    .c-form1 {
      &__content {
        padding-left: 69px;
        margin-top: -4px;

        @include r.max-w(1024px) {
          padding-left: 40px;
        }

        @include r.sp {
          padding-left: 0;
          margin-top: 0;
        }
      }
      &__item {
        display: flex;

        @include r.sp {
          display: block;
        }

        & + .c-form1__item {
          margin-top: 12px;
        }
        &.is-privacy {
          display: none;
        }
      }

      &__label {
        margin-bottom: 0;
        width: 281px;
        padding-right: 20px;
        flex-shrink: 0;

        @include r.max-w(1024px) {
          width: 200px;
        }

        @include r.sp {
          width: 100%;
          padding-right: 0;
        }
        &:after {
          content: none !important;
        }
      }
      &__checked,
      &__btn,
      &__note,
      &__choose {
        display: none;
      }
      &__input,
      &__select,
      &__textarea {
        font-size: 1.4rem;
        word-break: break-all;
      }
      &__text {
        position: relative;
        top: 2px;

        @include r.sp {
          top: 0;
        }
      }
      &__grbtn {
        display: flex;
        @include r.max-w(1024px) {
          margin-left: -20px;
        }
        @include r.sp {
          display: block;
          margin-left: 0;
        }
      }
      &__step {
        @include r.max-w(1024px) {
          width: 270px;
          padding: 32px 15px 30px;
          @include r.sp {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  }

  &.is-complete {
    .c-form1 {
      &__content {
        padding-left: 67px;
        margin-top: 1px;
        max-width: 100%;

        @include r.sp {
          padding-left: 0;
          margin-top: 0;
        }
      }
    }
  }

  &__step {
    background: #fff;
    box-shadow: 0 4px 50px rgba($color: #000000, $alpha: 0.15);
    width: 314px;
    padding: 32px 30px 30px;
    flex-shrink: 0;
    position: sticky;
    top: 145px;
    @include r.sp {
      padding: 0;
      text-align: center;
      position: fixed;
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 11;
      transition: all 0.5s ease-in-out;
      display: none;
    }
    &lst {
      display: flex;
      counter-reset: step-counter;
      @include r.sp {
        display: none;
      }
    }

    &item {
      counter-increment: step-counter;
      font-size: 1.4rem;
      font-weight: 300;
      color: v.$color7;

      &::before {
        content: 'STEP.' counter(step-counter);
        display: block;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1;
      }

      &.is-active {
        color: v.$color1;
      }

      & + .c-form1__stepitem {
        margin-left: 20px;
      }
    }

    &txt {
      font-size: 1.4rem;
      font-weight: 300;
      color: v.$color1;
      margin-top: 2px;
      @include r.sp {
        margin-top: 0;
      }
    }
  }
  &__inner {
    width: 100%;
  }
  &__content {
    flex: 1;
    padding-left: 50px;
    margin-top: -8px;
    max-width: 730px;

    @include r.sp {
      padding-left: 0;
      margin-top: 0;
      max-width: 100%;
    }
    .error {
      font-size: 1.3rem !important;
      line-height: 1.5;
    }
  }

  &__item {
    & + .c-form1__item {
      margin-top: 19px;
    }

    &.is-required {
      .c-form1__label {
        &::after {
          content: '必須';
          width: 44px;
          background: v.$color5;
          color: #fff;
          border-radius: 11px;
          text-align: center;
          display: inline-block;
          padding: 4px 0;
          font-size: 1.4rem;
          line-height: 1;
          font-weight: bold;
          margin-left: 11px;
        }
      }
    }
  }

  &__label {
    font-size: 1.6rem;
    font-weight: bold;
    color: v.$color1;
    margin-bottom: 6px;

    @include r.sp {
      margin-bottom: 7px;
      line-height: 24px;
    }
  }

  &__select {
    select {
      appearance: none;
      outline: none;
      font-size: 1.4rem;
      background: v.$color9 url(../images/common/icon/icon_arrow_down_black.svg)
        no-repeat;
      background-position: center right 18px;
      background-size: 12px 9px;
      color: #292929;
      font-weight: 300;
      padding: 15px 23px;
      height: 50px;
      width: 100%;
      border-radius: 0;
      @include r.sp {
        padding: 15px 20px;
      }
    }

    &.is-category {
      max-width: 400px;

      @include r.sp {
        max-width: 100%;
      }
    }

    &.is-prefectures {
      max-width: 257px;

      @include r.sp {
        max-width: 170px;
      }
      select {
        @include r.sp {
          padding: 12px 20px 15px;
          line-height: 25px;
        }
      }
    }
  }

  &__input {
    input {
      appearance: none;
      outline: none;
      font-size: 1.4rem;
      background: v.$color9;
      color: #292929;
      font-weight: 300;
      padding: 15px 23px;
      height: 50px;
      width: 100%;
      border-radius: 0;

      @include r.sp {
        padding: 15px 20px;
      }
    }

    &.is-postcode {
      max-width: 170px;
    }

    &.is-phone {
      input {
        max-width: 257px;

        @include r.sp {
          max-width: 225px;
        }
      }
    }
  }

  &__textarea {
    textarea {
      appearance: none;
      outline: none;
      font-size: 1.4rem;
      background: v.$color9;
      color: #292929;
      font-weight: 300;
      padding: 20px 23px;
      width: 100%;
      height: 250px;
      resize: none;
      border-radius: 0;
      @include r.sp {
        padding: 15px 20px;
        height: 180px;
      }
    }
  }

  &__note {
    font-size: 1.4rem;
    font-weight: 300;
    margin: -8px 0 6px;
    color: #292929;
    @include r.sp {
      @include r.YuGothic;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0.05em;
      margin: -4px 0 7px;
    }
  }

  &__bl {
    display: flex;
    align-items: center;
  }

  &__btn {
    width: 86px;
    font-size: 1.4rem;
    font-weight: bold;
    color: #fff;
    background: #0066ff;
    border-radius: 40px;
    padding: 5px 0 3px;
    margin-left: 20px;
    max-height: 40px;
  }

  &__privacy {
    position: relative;
    background: v.$color9;
    padding: 17px 24px 8px 30px;
    position: relative;

    @include r.sp {
      padding: 20px 16px 28px 20px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 21px;
      right: 25px;
      width: 2px;
      height: 210px;
      border-right: 2px solid #a6a6a6;

      @include r.sp {
        height: 85%;
        right: 17px;
      }
    }

    &txt {
      font-size: 1.4rem;
      line-height: 25px;
      font-weight: 300;
      color: v.$color1;
      overflow-x: hidden;
      overflow-y: auto;
      height: 225px;
      padding-right: 30px;
      padding-bottom: 30px;
      position: relative;
      z-index: 1;

      @include r.sp {
        font-size: 1.2rem;
        line-height: 21px;
        height: 314px;
        padding-right: 20px;
        padding-bottom: 0;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: v.$color5;
      }
      .scrollbar-track-y {
        width: 4px !important;
        background: transparent;
      }
      .scrollbar-thumb {
        width: 4px !important;
        border-radius: 0;
        background: #0066ff;
      }

      .u-text1 {
        margin: 25px 0 10px;
        font-weight: 600;
      }
      .u-numbertel {
        margin-right: 10px;
        display: inline-block;
        pointer-events: none;
        @include r.sp {
          pointer-events: auto;
        }
      }
      ul {
        padding-left: 30px;
        margin-top: 10px;
        list-style: disc;
        li {
          list-style-type: decimal;
        }
        ol {
          padding-left: 30px;
          margin-top: 10px;
          li {
            list-style-type: decimal;
          }
        }
      }
    }
  }

  &__checked {
    font-size: 1.4rem;
    line-height: 20px;
    font-weight: 300;
    color: v.$color1;
    text-align: center;
    margin: 31px 0 52px;

    @include r.sp {
      margin: 30px 0 40px;
    }

    input {
      appearance: none;
      outline: none;
      background: v.$color9;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      cursor: pointer;
      border-radius: 0;

      &:checked {
        background: v.$color9 url(../images/common/icon/icon_check.svg)
          no-repeat center;
        background-size: 13px 10px;
      }
    }
    > span {
      &.error {
        margin-top: 10px;
      }
      > label {
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        > input {
          vertical-align: text-bottom;
        }
      }
    }
  }

  .c-btn01 {
    &__link {
      background: transparent;
      transition: all 0.3s ease-in-out;
      font-family: 'dnp-shuei-gothic-gin-std', sans-serif;
    }
  }

  &__text {
    font-size: 1.4rem;
    font-weight: 300;
    color: v.$color1;
  }

  &__grbtn {
    display: none;
    align-items: center;
    margin-top: 70px;
    margin-left: -55px;

    @include r.sp {
      margin-left: 0;
      margin-top: 43px;
    }

    .c-btn01 {
      margin: 0 10px;

      @include r.sp {
        margin: 0 auto;

        &.is-back {
          margin-bottom: 16px;
        }
      }
    }
  }

  &__thanks {
    &tit {
      font-size: 2rem;
      font-weight: bold;
      color: v.$color5;
      margin-bottom: 9px;
      letter-spacing: 0.1em;

      @include r.sp {
        font-size: 1.8rem;
        line-height: 28.8px;
        margin-bottom: 17px;
      }
    }

    &txt {
      font-size: 1.6rem;
      line-height: 32px;
      font-weight: 300;
      color: v.$color1;

      @include r.sp {
        font-size: 1.4rem;
        line-height: 25px;
      }
    }
  }
  .error {
    margin-top: 5px;
  }
}
