@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
img
------------------------------------------------------------*/
.c-imgtext01 {
  background: url(p.$path + 'images/common/c-imgtext1-bg.png') no-repeat center
    left;
  background-size: cover;
  padding: 83px 0 85px;
  @include r.mq(xlless) {
    padding: 50px 0 50px;
  }
  @include r.mq(mdless) {
    background: url(p.$path + 'images/common/c-imgtext1-bg-sp.jpg') no-repeat
      center left;
    background-size: cover;
    padding: 0;
    margin-top: 140px;
    margin-left: 20px;
  }

  &__inner {
    display: flex;
    margin-right: auto;
    max-width: calc(50% + 620px);
    align-items: center;
    @include r.mq(xlless) {
      max-width: calc(50% + 310px);
    }
    @include r.mq(mdless) {
      flex-direction: column;
      position: relative;
      max-width: 100%;
    }
  }

  &__img {
    width: 53.1%;
    @include r.mq(xlless) {
      width: 55%;
    }
    @include r.mq(mdless) {
      width: 100%;
      margin-top: -142px;
      margin-left: -40px;
    }
  }

  &__txt1 {
    margin-top: 26px;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 32px;
    letter-spacing: 0.1em;
    color: v.$color1;
    padding-left: 6px;
    @include r.mq(mdless) {
      font-size: 1.4rem;
      margin-top: 16px;
      line-height: 180%;
      letter-spacing: -0.2px;
      padding-left: 0;
      font-weight: 300;
    }
  }

  &__detail {
    margin-left: 85px;
    margin-bottom: 32px;
    flex: 1;
    @include r.mq(xlless) {
      margin-left: 56px;
    }

    @include r.mq(mdless) {
      margin-left: 0;
      padding: 0 30px;
      margin: 34px 0 50px;
      flex: none;
      width: 100%;
    }
  }

  .c-btn01 {
    margin-top: 51px;
    margin-left: 7px;
    @include r.mq(mdless) {
      margin-top: 23px;
      margin-left: 0px;
    }
  }
}

/*------------------------------------------------------------
c-imgtext02
------------------------------------------------------------*/
.c-imgtext02 {
  display: flex;
  align-items: flex-start;

  @include r.mq(mdless) {
    flex-direction: column;
  }

  &__img {
    width: 49.4%;
    @include r.mq(xlless) {
      width: 35%;
    }
    @include r.mq(mdless) {
      width: 100%;
    }
  }

  &__detail {
    margin-left: 62px;
    margin-top: 72px;
    flex: 1;
    @include r.mq(xlless) {
      margin-left: 40px;
      margin-top: 30px;
    }
    @include r.mq(mdless) {
      margin-top: 25px;
      margin-left: 0;
    }
  }

  &__txt1 {
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 0.15em;
    color: v.$color1;
    margin-top: 21px;
    line-height: 42px;
    @include r.mq(mdless) {
      font-weight: 400;
      margin-top: 16px;
      letter-spacing: 0.5px;
      line-height: 38px;
    }
  }

  .c-btn01 {
    margin-top: 54px;
    margin-left: 0;
    @include r.mq(mdless) {
      margin: 0 auto;
      margin-top: 33px;
    }
  }
  &.is-style1 {
    gap: 0 60px;
    align-items: center;
    @include r.mq(xlless) {
      gap: 0 30px;
    }
    @include r.sp {
      flex-direction: column;
    }
    .c-imgtext02 {
      &__img {
        max-width: 620px;
        width: 100%;
        border-radius: 50%;
        overflow: hidden;
        @include r.mq(xlless) {
          width: 35%;
        }
        @include r.sp {
          width: 100%;
          margin-bottom: 23px;
        }
      }
      &__ttl {
        font-weight: 600;
        font-size: 2.8rem;
        line-height: 50px;
        letter-spacing: 0.1em;
        color: #0066ff;
        margin: 0 0 25px;
        @include r.mq(xlless) {
          font-size: 2.2rem;
          line-height: 36px;
          margin: 0 0 14px;
        }
        @include r.sp {
          font-size: 2rem;
        }
      }
      &__detail {
        margin: 0;
        padding-bottom: 26px;
      }
      &__txt1 {
        line-height: 35px;
        letter-spacing: 0.12em;
        @include r.sp {
          line-height: 29px;
          letter-spacing: 0;
          margin-top: 0;
        }
      }
    }
  }
}

/*------------------------------------------------------------
c-imgtext03
------------------------------------------------------------*/
.c-imgtext03 {
  &__inner {
    display: flex;
    align-items: flex-start;
    max-width: calc(50% + 620px);
    margin-left: auto;
    position: relative;

    @include r.sp {
      flex-direction: column;
    }
  }

  &__img {
    position: absolute;
    z-index: 0;
    width: 53.7%;

    @include r.sp {
      width: 100%;
      position: relative;
      padding: 0 0 0 13px;
      z-index: 1;
    }
  }

  &__detail {
    position: relative;
    left: 0;
    right: 0;
    z-index: 1;
    margin-left: auto;
    width: 58%;
    margin-top: 110px;
    padding: 56px 48px 43px 53px;
    &::after {
      content: '';
      background: radial-gradient(
        109.66% 473.83% at 112.5% 102.96%,
        rgba(226, 217, 217, 0) 0%,
        #fcf3e7 39.28%,
        #d2e4ff 100%
      );
      opacity: 0.3;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    @include r.mq(xlless) {
      width: 60%;
      margin-top: 35px;
      padding: 30px 30px 30px 30px;
    }

    @include r.sp {
      width: 100%;
      margin-top: -105px;
      padding: 145px 20px 49px 20px;
      z-index: 0;
    }
  }

  .c-title02 {
    letter-spacing: 7.2px;
    line-height: 60px;
    margin-bottom: 31px;

    @include r.mq(xlless) {
      letter-spacing: 3.8px;
      line-height: 40px;
      margin-bottom: 30px;
      font-size: 2rem;
    }

    @include r.sp {
      font-size: 2.4rem;
      letter-spacing: 2.2px;
      line-height: 37px;
      margin-bottom: 23px;
    }

    @include r.max-w(374px) {
      font-size: 2.2rem;
    }
  }

  &__txt1 {
    margin-bottom: 14px;
    padding-left: 5px;
    letter-spacing: 2px;
    line-height: 31px;
    font-weight: 500;
    font-size: 1.6rem;
    color: v.$color1;
    position: relative;
    z-index: 1;

    @include r.mq(xlless) {
      line-height: 24px;
      margin-bottom: 10px;
    }

    @include r.sp {
      margin-bottom: 27px;
      padding-left: 0;
      line-height: 29.3px;
    }
  }

  &__show-more {
    @include r.sp {
      display: none;
    }
  }

  &__sign {
    margin-top: 10px;
    font-weight: 400;
    font-size: 1.8rem;
    color: v.$color1;
    position: relative;
    z-index: 1;
    @include r.mq(xlless) {
      font-size: 1.6rem;
      line-height: 24px;
      margin-top: 20px;
    }
  }

  &__name {
    margin-left: 10px;
    font-weight: 600;
    font-size: 3rem;
    color: v.$color1;
    @include r.mq(xlless) {
      font-size: 2.4rem;
    }
  }

  .c-btn01 {
    @include r.sp {
      margin-top: 22px;
    }

    &__link {
      &::after {
        background: url(p.$path + 'images/common/icon/icon_plus.svg') no-repeat
          center;
        width: 13px;
        height: 13px;
      }
    }
  }
}

/*------------------------------------------------------------
c-imgtext04
------------------------------------------------------------*/
.c-imgtext04 {
  display: flex;
  align-items: flex-start;
  $root: &;

  @include r.mq(mdless) {
    flex-direction: column;
  }

  &__img {
    width: 44.4%;
    position: relative;
    z-index: 1;
    @include r.mq(xlless) {
      width: 35%;
    }
    @include r.mq(mdless) {
      width: 100%;
    }
  }

  &__detail {
    margin-left: 90px;
    margin-top: -21px;
    width: 48%;
    @include r.mq(xlless) {
      margin-left: 50px;
      margin-top: -21px;
      width: 55%;
    }
    @include r.mq(mdless) {
      margin-top: -36px;
      margin-left: 0;
      width: 100%;
    }

    @include r.max-w(374px) {
      margin-top: -30px;
    }

    .c-title07 {
      &__en {
        margin-left: -155px;
        margin-top: 10px;

        @include r.mq(xlless) {
          margin-left: -100px;
        }

        @include r.sp {
          margin-left: 0;
          margin-top: 0;
        }
      }
      &__jp {
        font-size: 4rem;
        letter-spacing: 3.8px;

        @include r.mq(xlless) {
          font-size: 3rem;
          margin-top: 10px;
        }

        @include r.sp {
          font-size: 2.6rem;
          margin-top: -3px;
          padding: 0 20px;
          letter-spacing: 2.5px;
        }
      }
    }
  }

  &__txt1 {
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: 2px;
    color: v.$color1;
    margin-top: 2px;
    line-height: 32px;
    @include r.mq(mdless) {
      margin-top: 2px;
      padding: 0 5px 0 20px;
      letter-spacing: 0.05em;
      line-height: 29px;
    }
  }

  .c-btn01 {
    margin-top: 40px;
    margin-left: 0;

    @include r.mq(xlless) {
      margin-top: 30px;
    }

    @include r.mq(mdless) {
      margin: 0 auto;
      margin-top: 36px;
    }
  }

  &--style1 {
    @include r.sp {
      flex-direction: column-reverse;
    }
    #{$root} {
      &__detail {
        margin-top: -21px;
        margin-right: 90px;
        margin-left: auto;
        width: 39.4%;

        @include r.mq(xlless) {
          margin-top: -21px;
          margin-right: 50px;
          margin-left: auto;
          width: 46%;
        }

        @include r.sp {
          width: 100%;
          margin-right: 0;
          margin-top: -37px;
        }

        @include r.max-w(374px) {
          margin-top: -30px;
        }
        .c-title07 {
          &__en {
            margin-left: 0;
            margin-right: -159px;
            text-align: right;

            @include r.mq(xlless) {
              margin-right: -80px;
            }

            @include r.sp {
              margin-right: 0;
              text-align: left;
            }
          }
        }

        .c-btn01 {
          @include r.sp {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

/*------------------------------------------------------------
c-imgtext05
------------------------------------------------------------*/
.c-imgtext05 {
  display: flex;
  align-items: flex-start;

  @include r.sp {
    flex-direction: column;
  }

  &__img {
    margin-right: 71px;
    width: 32.2%;

    @include r.mq(xlless) {
      margin-right: 40px;
      width: 30%;
    }

    @include r.sp {
      width: 60%;
      margin: 0 auto;
    }
  }

  &__detail {
    flex: 1;
    margin-top: 63px;

    @include r.mq(xlless) {
      margin-top: 40px;
    }

    @include r.sp {
      flex: auto;
      margin-top: 26px;
    }
  }

  &__item {
    margin-bottom: 22px;

    @include r.sp {
      margin-bottom: 57px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: v.$color1;
    padding-bottom: 9px;
    border-bottom: 1px solid v.$color26;

    @include r.mq(xlless) {
      font-size: 1.8rem;
    }

    @include r.sp {
      font-size: 1.8rem;
      line-height: 36px;
      padding-bottom: 5px;
    }
  }

  &__subtitle1 {
    margin-top: 18px;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 24px;
    letter-spacing: 0.12em;
    color: v.$color5;

    @include r.mq(xlless) {
      font-size: 1.6rem;
    }

    @include r.sp {
      font-size: 1.6rem;
      margin-top: 10px;
      letter-spacing: 0px;
    }
  }

  &__txt1 {
    margin-top: 16px;
    font-weight: 400;
    font-size: 1.6rem;
    color: v.$color1;
    line-height: 29px;

    @include r.mq(xlless) {
      font-size: 1.4rem;
    }

    @include r.sp {
      font-size: 1.6rem;
      margin-top: 9px;
      line-height: 24px;
    }
  }
  &.is-style1 {
    .c-imgtext05 {
      &__img {
        margin-right: 58px;
        @include r.mq(xlless) {
          margin-right: 40px;
          width: 30%;
        }
        @include r.sp {
          width: 60%;
          margin-right: auto;
        }
      }
      &__detail {
        margin-top: 13px;
        @include r.sp {
          margin-top: 34px;
        }
      }
      &__txt1 {
        margin-top: 8px;
        padding-left: 7px;
        @include r.sp {
          padding-left: 0;
        }
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-imgtext06 {
  &__inner {
    display: flex;
    gap: 0 31px;
    @include r.sp {
      gap: 19px 0;
      flex-direction: column;
    }
  }
  &__img {
    position: relative;
    overflow: hidden;
    max-width: 642px;
    width: calc(642px * 100% / 1340px);
    align-self: flex-start;
    @include r.sp {
      max-width: 100%;
      width: 100%;
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:after {
      content: '';
      display: block;
      padding-top: calc(428px / 642px * 100%);
    }
  }
  .c-text1 {
    padding-right: 10px;
    width: calc(658px * 100% / 1340px);
    @include r.sp {
      width: 100%;
      padding-right: 0;
      line-height: 27px;
    }
  }
  .c-title02 {
    margin-bottom: 35px;
    @include r.sp {
      margin-bottom: 20px;
    }
  }
}

/*
------------------------------------------------------------*/
.c-imgtext07 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  $root: &;
  @include r.max-w(1240px) {
    padding: 0;
  }

  @include r.sp {
    flex-wrap: wrap;
  }

  &__info {
    padding: 0 0 5px;
    position: relative;
    @include r.max-w(940px) {
      padding: 0 20px 0 0;
    }
    @include r.sp {
      width: 100%;
      padding: 0;
      z-index: 2;

      svg {
        margin-bottom: 11px;
      }
    }
  }

  &__ttl {
    overflow: hidden;
    font-size: 4.6rem;
    line-height: 1.45;
    color: v.$color1;
    font-weight: 600;
    letter-spacing: 0.1em;
    @include r.max-w(1240px) {
      font-size: calc((46 / 1240) * 100vw);
    }
    @include r.sp {
      font-size: 2.4rem;
    }

    &__txt {
      display: block;
      overflow: hidden;

      span {
        display: inline-block;
        position: relative;
        transform: translate(160px, 50px);
        -webkit-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
        transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
        color: v.$color18;
        line-height: 1;
        will-change: transform;
        opacity: 0;
      }
    }
    &.is-active {
      #{$root}__ttl__txt {
        span {
          opacity: 1;
          transform: translate(0, 0);
          color: v.$color1;
          animation: 0.8s animation_text forwards cubic-bezier(0, 0.55, 0.45, 1);
        }
      }
    }
  }

  &__txt {
    font-size: 2rem;
    line-height: 1.2;
    color: v.$color1;
    font-weight: 500;
    letter-spacing: 0.12em;
    @include r.Mulish;
    margin: 22px 0 0 6px;
    @include r.max-w(1240px) {
      font-size: calc((20 / 1240) * 100vw);
    }
    @include r.sp {
      font-size: 1.8rem;
      font-weight: 500;
      margin: 9px 0 0;
    }
  }

  &__scroll {
    font-size: 1.6rem;
    line-height: 1.25;
    color: v.$color1;
    font-weight: 300;
    letter-spacing: 0.08em;
    @include r.Mulish;
    margin: 131px 0 0 8px;
    @include r.max-w(1240px) {
      font-size: calc((16 / 1240) * 100vw);
      margin: calc((131 / 1240) * 100vw) 0 0 8px;
    }
    @include r.sp {
      font-size: 1.4rem;
      margin: 79px 0 0;
    }

    span {
      display: inline-block;
    }
    svg {
      display: block;
    }
    .u-textsvg {
      @include r.sp {
        transform: scale(0.9);
      }
    }
  }
  &__boxpic {
    position: relative;
    max-width: 450px;
    width: 100%;

    @include r.max-w(940px) {
      max-width: 381px;
    }

    @include r.sp {
      width: 100%;
      margin-top: 10px;
      z-index: 1;
      max-width: 100%;
    }
  }
  &__bgimg {
    position: relative;
    overflow: hidden;
    max-width: 450px;
    width: 100%;
    &:after {
      content: '';
      display: block;
      padding-top: calc(563px / 450px * 100%);
      @include r.mq(lgless) {
        padding-top: 80%;
      }
      @include r.sp {
        padding-top: calc(563px / 450px * 100%);
      }
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    @include r.max-w(940px) {
      max-width: 381px;
    }

    @include r.sp {
      max-width: 100%;
    }
  }
  &__bgimg2 {
    max-width: 450px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    min-width: 342px;

    @include r.max-w(940px) {
      max-width: 381px;
    }

    @include r.sp {
      max-width: 100%;
      width: 100%;
      position: relative;
      min-width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }
  &__txt2 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 42px;
    letter-spacing: 0.12em;
    color: #292929;
    padding-left: 7px;

    @include r.max-w(940px) {
      padding: 0;
    }

    @include r.sp {
      line-height: 35px;
      letter-spacing: 0;
    }
  }

  .js-imgfixed1 {
    width: 450px;

    @include r.sp {
      width: 100%;
    }
  }

  .js-imgcircle {
    stroke-dasharray: 0, 266;

    @include r.sp {
      stroke-dasharray: 191, 266;
    }
  }
}

/*
------------------------------------------------------------*/
.c-imgtext08 {
  display: flex;
  flex-direction: row-reverse;
  gap: 0 80px;
  padding-left: calc((100% - 1240px) / 2);
  @include r.mq(xlless) {
    padding-left: 20px;
    gap: 0 40px;
  }
  @include r.sp {
    flex-direction: column;
  }
  &__info {
    max-width: 540px;
    width: 100%;
    flex-shrink: 0;
    @include r.mq(xlless) {
      width: 55%;
    }
    @include r.sp {
      width: 100%;
      max-width: 100%;
      padding-right: 20px;
      margin-top: 25px;
    }
  }
  &__ttl {
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 50px;
    letter-spacing: 0.1em;
    color: #0066ff;
    margin-bottom: 22px;
    @include r.mq(xlless) {
      font-size: 2.2rem;
      margin-bottom: 14px;
      line-height: 36px;
    }
    @include r.sp {
      font-size: 2rem;
      line-height: 36px;
      letter-spacing: 0.1em;
    }
  }
  &__detail {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 35px;
    letter-spacing: 0.12em;
    @include r.sp {
      line-height: 29px;
      letter-spacing: 0;
    }
  }
}

/*
------------------------------------------------------------*/
.c-imgtext09 {
  $root: &;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-right: 7.8%;
    @include r.max-w(1240px) {
      padding-right: 5%;
    }
    @include r.sp {
      padding: 0;
    }
  }

  &__detail {
    width: 47%;

    @include r.sp {
      width: 100%;
      margin-top: 22px;
      order: 1;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 2.6rem;
    line-height: 47px;
    letter-spacing: 0.15em;
    color: v.$color2;
    @include r.max-w(1240px) {
      font-size: calc((26 / 1240) * 100vw);
    }
    @include r.sp {
      font-size: 1.8rem;
      line-height: 32px;
    }
  }

  &__txt {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 40px;
    color: #ffffff;
    margin-top: 35px;
    @include r.max-w(1240px) {
      font-size: calc((18 / 1240) * 100vw);
    }
    @include r.sp {
      font-size: 1.4rem;
      margin-top: 21px;
      line-height: 28px;
    }
  }

  &__list {
    width: 42.9%;
    @include r.sp {
      width: 100%;
      order: 0;
    }
  }

  &__item {
    margin-bottom: 66px;
    @include r.max-w(1240px) {
      margin-bottom: 30px;
    }
    @include r.sp {
      margin-bottom: 17px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__img {
    img {
      width: 100%;
    }

    &.is-style1 {
      width: 83.6%;
    }

    &.is-style2 {
      width: 64%;
      margin-left: auto;
    }

    &.is-style3 {
      width: 64%;
      @include r.sp {
        width: 64.5%;
      }
    }

    &.is-style4 {
      width: 83.6%;
      margin-left: auto;
      @include r.sp {
        width: 84.5%;
      }
    }
  }

  &--reverse {
    #{$root} {
      &__inner {
        flex-direction: row-reverse;
      }

      &__list {
        width: 45.9%;
        @include r.sp {
          width: 100%;
        }
      }

      &__txt {
        margin-top: 29px;

        @include r.sp {
          margin-top: 17px;
        }
      }

      &__detail {
        width: 44.3%;

        @include r.sp {
          width: 100%;
        }
      }
    }
  }

  &--noimage {
    #{$root}__inner {
      max-width: 1220px;
      margin: 0 auto;
      padding: 0 7.8%;
      @include r.max-w(1240px) {
        padding: 0 5%;
      }
      @include r.sp {
        padding: 0;
      }
    }

    #{$root}__detail {
      width: 100%;
    }

    #{$root}__txt {
      font-size: 1.6rem;
      margin-top: 24px;
      @include r.sp {
        font-size: 1.4rem;
        margin-top: 14px;
      }
    }
  }
}
