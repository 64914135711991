@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
text
------------------------------------------------------------*/
.c-text1 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 29px;
  color: #292929;
  @include r.sp {
    line-height: 32px;
  }
}

.c-text2 {
  overflow: hidden;
  &__txt {
    overflow: hidden;
    span {
      display: inline-block;
      position: relative;
      transform: translate(160px, 50px);
      -webkit-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
      transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
      color: v.$color18;
      will-change: transform;
      opacity: 0;
    }
  }
  &.is-active {
    .c-text2__txt {
      span {
        opacity: 1;
        transform: translate(0, 0);
        color: v.$color1;
        animation: 0.8s animation_text forwards cubic-bezier(0, 0.55, 0.45, 1);
      }
    }
  }
}

@keyframes animation_text {
  0% {
    opacity: 0.5;
    visibility: hidden;
    transform: translate(160px, 50px) scale(0.5);
    // scale: 0.5;
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0) scale(1);
    // scale: 1;
  }
}
